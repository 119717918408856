import React from "react";
import { Fade, Modal } from "@mui/material";
import { ModalsStyles } from './Modals.styles';
// Modales
import ModalMovimientos from "../../../pages/saldos/ModalMovimientos";
import ModalDetalle from "../../../pages/aCobrar/ModalDetalle";
import ModalConfirmar from "../../../pages/pagos/Cobros/ModalConfirmar";
// recoil 
import { useRecoilValue } from 'recoil'
import { formIsOpen, routeInfo } from '../../../recoilState/GlobalState';

export default function TransitionModal(props) {
    const classes = ModalsStyles();
    const isOpen = useRecoilValue(formIsOpen);
    const { wordKey: route } = useRecoilValue(routeInfo);

    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
                open={isOpen}
            >
                <Fade in={isOpen} className={classes.modal}>
                    <div className={classes.modal}>
                        <div className={classes.paper} style={{width:  route === 'Saldos' ? '90%' : '50%'}}>
                            {route === 'Saldos' && <ModalMovimientos props={props} />}
                            {route === 'A Cobrar' && <ModalDetalle />}
                            {route === 'Cobros > Ver cobros' && <ModalConfirmar props={props}/>}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
