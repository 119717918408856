import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useFormik } from 'formik';
import {FormikSelectInput, FormikTextInput} from "../../../utils/inputs/FormikInputs";
import { useStyles } from './styles';
import {tipoDeSociedadGet} from "../../../../services/hooli-services/Comercios/tipoDeSociedadGet"
import {cargosGet} from "../../../../services/hooli-services/Comercios/cargosGet"
import {rubroMerchantGet} from "../../../../services/hooli-services/Comercios/rubroMerchantGet"
import { useRecoilState, useRecoilValue } from 'recoil';
import { afipData, loading, userCredentials } from '../../../../recoilState/GlobalState';
function Identificacion({formik, setIsOpen}) {
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [load, setLoad] = useRecoilState(loading);
    const recoilAfipData = useRecoilValue(afipData);
    const [tipoDeSociedad, setTipoDeSociedad] = useState([]);
    const [rubros, setRubros] = useState([])
    const [cargos, setCargos] = useState([])
    const styles = useStyles();
    
    const dataFormik = { 
    razonSocial: {label: "Razón Social", placeholder:""}, 
    tipoDePersona: {label:"Persona Física/Jurídica", placeholder:"Física"}, 
    cuit:{label:"CUIT", placeholder:"30-22222222-8"},
    nombre: {label:"Nombre de tu comercio*", placeholder:""},
    tipoDeSociedad: {label: "Tipo de Sociedad*", placeholder:"Sociedad Anónima"},
    rubro: {label:"Rubro de tu comercio*", placeholder: "Heladeria"},
    cargo: {label: "Cargo*", placeholder:"Representante legal"},
    }
console.log(tipoDeSociedad)
    const getSociedad = async() => {
        if(recoilAfipData.personaFJ === "J"){
            let sociedad = await tipoDeSociedadGet(credentials)
            if(sociedad.status.code === 1){
                setTipoDeSociedad(sociedad.result)
                // formik.setFieldValue("tipoDeSociedad", sociedad.result[0].tipoSocietarioId);
            }
        } else if (recoilAfipData.personaFJ === "F"){
            setTipoDeSociedad([{tipoSocietarioId: 0, tipoSocietarioNombre: "Ninguno"}])
        }
    }
    const getCargo = async() => {
        let cargo = await cargosGet(credentials)
        console.log(cargo)
        if(cargo.status.code === 1){
            const filterCargo = cargo.result.filter((cargo)=> cargo.cargoPFJ === recoilAfipData.personaFJ);
            setCargos(filterCargo);
            // formik.setFieldValue("cargo", filterCargo[0]?.cargoId);
        }
    }
    const getRubros = async() => {
        let rubro = await rubroMerchantGet(credentials)
        if(rubro.status.code === 1){
            setRubros(rubro.result)
            // formik.setFieldValue("rubro", rubro.result[0].rubroMerchantId);
        }
    }

    const selectGet = async() => {
            await Promise.all([getSociedad(), getCargo(), getRubros()])
            setLoad(false);
    }
    
    useEffect(() => {
        selectGet()
    }, [])
    
  return (
    <Grid width={"100%"} padding={"20px 0 20px 0"}>  
        <Box width={"100%"} className={styles.inputRazonSocial}>
            <FormikTextInput data={dataFormik} name={"razonSocial"} formik={formik} disabled={true}/>
        </Box>
        <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"tipoDePersona"} formik={formik} disabled={true}/>
            <FormikSelectInput data={dataFormik} name={"tipoDeSociedad"} formik={formik} options={tipoDeSociedad} optionName="tipoSocietarioNombre" optionValue="tipoSocietarioId" optionKey="tipoSocietarioId" noSelect={recoilAfipData.personaFJ === "F" ? false : true} disabled={recoilAfipData.personaFJ === "F"}/>
        </Box>
        <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"cuit"} formik={formik} disabled={true}/>
            <FormikSelectInput data={dataFormik} name={"rubro"} formik={formik} options={rubros} optionName="rubroMerchantNombre" optionValue="rubroMerchantId" optionKey="rubroMerchantId" noSelect={true}/>
        </Box>
        <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"nombre"} formik={formik}/>
            <FormikSelectInput data={dataFormik} name={"cargo"} formik={formik} options={cargos} optionName="cargoNombre" optionValue="cargoId" optionKey="cargoId" noSelect={true}/>
        </Box>
    </Grid>
  )
}

export {Identificacion}
