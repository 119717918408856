import React from 'react';
import { Layaout } from '../../../components/layaout';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { afipData } from '../../../recoilState/GlobalState';

const PF = [
    {title: "Constancia de Inscripción de AFIP"},
    {title: "Constancia de Inscripción de Ingresos Brutos (IIBB)"},
    {title: "En caso de corresponder, Constancia de inscripción de Sujeto Obligado ante UIF"},
    {title: "Otros documentos como Exenciones de Ingresos Brutos (IIBB)"},
]
const PJ = [
    {title: "Constancia de Inscripción de AFIP"},
    {title: "Constancia de Inscripción de Ingresos Brutos (IIBB)"},
    {title: "En caso de corresponder, Constancia de inscripción de Sujeto Obligado ante UIF"},
    {title: "Otros documentos como Exenciones de Ingresos Brutos (IIBB)"},
    {title: "Contrato Social"},
    {title: "Designación de autoridades vigente"},
    {title: "Registro de Accionistas"},
    {title: "Ultimo balance de certificado"},
    {title: "Acta de Aprobación de balance"},
    {title: "Apoderados"},
    {title: "Beneficiarios final (DNI y documentos relacionados)"}, 
]

function QueNecesitas() {
    const recoilAfipData = useRecoilValue(afipData);
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate('/alta-comercios');
    }

    const handleBack = () => {
        navigate('/');
    };
    console.log(recoilAfipData);
  return (
    <Layaout title={"Tené en cuenta que tenés que contar con la siguiente información"} subtitle={recoilAfipData?.personaFJ === "F" ? "" : "Tené en cuenta que solamente teniendo el cargo de apoderado, presidente, representante legal o socio gerente podrás dar de alta tu comercio. "}>
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"start"} marginBlock={"2em"} gap={2}> 
            {recoilAfipData?.personaFJ === "F" && <h2 style={{fontSize:"22px", color:"#006E54", fontWeight:"700"}}>Personas Físicas</h2>}
            <ol>
                {
                    recoilAfipData?.personaFJ === "F" && PF.map((e, i)=> (
                        <li style={{color:"#595959", fontSize:"19px"}} key={i}>{e.title}</li>
                    ))
                }
            </ol>
            {recoilAfipData?.personaFJ === "J" && <h2 style={{fontSize:"22px", color:"#006E54", fontWeight:"700"}}>Personas Juridicas</h2>}
            <ol style={{display:"flex", flexDirection:"column", gap:"4px"}}>
                {
                    recoilAfipData?.personaFJ === "J" && PJ.map((e, i)=> (
                        <li style={{color:"#2b2b2a", fontSize:"19px"}} key={i}>{e.title}</li>
                    ))
                }
            </ol>
        </Box>
        <Box marginTop={"30px"}> 
            <ButtonGradient text={"Continuar"} size={"medium"} action={handleContinue}/>
        </Box>
        <Box marginTop={"15px"} marginBottom={"15px"}> 
            <ButtonGradient text={"Ir al Inicio"} size={"medium"} color="transparent" action={handleBack}/>
        </Box>
       
    </Layaout>
  )
}

export{ 
    QueNecesitas
}
