import { useEffect, useState } from 'react';
import { DataGridPro,LicenseInfo } from '@mui/x-data-grid-pro';
import { XGridStyles } from "./Xgrid.styles";
import { generateColumns, validateRowSelected } from '../../../services/Local-services';
import { routeInfo, rowSelected, userCredentials } from '../../../recoilState/GlobalState';
import { useRecoilState, useRecoilValue } from 'recoil'

export default function ServerPaginationGrid({
    titles,
    updateList = async () => { },
    onPageChange = () => { },
    onPageSizeChange = () => { },
    onNumberOfPagesChange = () => { },
    forceUpload = false
}) {
    const [isLoading, setIsLoading] = useState(true);
    const { route } = useRecoilValue(routeInfo);
    const [selected, setSelected] = useRecoilState(rowSelected);
    const [rows, setRows] = useState([]);
    const [pages, setPages] = useState({});
    const [rowCount, setRowCount] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [upload, setUpload] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const getList = async (newPage = 0, force = false) => {
        if (!force && pages[newPage] && newPage !== numberOfPages) {
            return setRows(pages[newPage])
        };
        setIsLoading(true);
        const { rows: newRows, rowCount: newRowCount } = await updateList(newPage, pageSize);

        setRows(newRows);

        const newPages = { ...pages };
        newPages[newPage] = newRows;
        setPages(newPages);

        setRowCount(newRowCount);
        setIsLoading(false);
    }

    useEffect(() => {
        if (rows.length > 0) return;
        getList();
    }, [pageSize, rows])

    useEffect(() => {
        setUpload(forceUpload);
        if (forceUpload !== upload) getList(page, true);
    }, [forceUpload]);

    const columns = generateColumns(titles, route);
    const classes = XGridStyles();
    LicenseInfo.setLicenseKey(
        "eaa04298c880ee5a01c3c1f8ecfc8314Tz04ODc1OSxFPTE3NDUwOTA4NjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
    );
    return (
        <DataGridPro
            columns={columns}
            rows={rows ?? []}
            rowCount={rowCount ?? 0}
            count={rowCount ?? 0}
            loading={isLoading}
            onRowClick={(data, event) =>
                validateRowSelected(data, event, selected.row, setSelected)
            }
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(newPage) => {
                setPage(newPage);
                onPageChange(newPage);
                getList(newPage);
            }}
            onStateChange={(data) => {
                const newPageSize = Math.ceil(data.state.viewportSizes.height / data.state.density.rowHeight) - 1;
                const newNumberOfPages = data.state.pagination.pageCount + 1;
                setPageSize(newPageSize);
                setNumberOfPages(newNumberOfPages);
                onPageSizeChange(newPageSize);
                onNumberOfPagesChange(newNumberOfPages);
            }}
            className={classes.root}
            autoPageSize
            hideFooterSelectedRowCount
            disableMultipleSelection
            rowHeight={36}
            disableColumnMenu
        />
    );
}