import React from 'react';
import { FiltrosStyles } from '../../Filtros.styles';
// Form
import { useFormik } from 'formik';
import * as yup from 'yup';
// Components
import { FormikDateInput, FormikSelectInput } from '../../../components/utils/inputs/FormikInputs';
import {
    Buttons,
    HeaderButton,
    SubmitButton
} from '../../../components'
//Assets
import reload from '../../../assets/icons/actualizar.svg'
import pesos from '../../../assets/icons/pesos.png'
import eliminar from '../../../assets/icons/eliminar.svg'
// Recoil
import { useRecoilValue } from 'recoil';
import { rowSelected } from '../../../recoilState/GlobalState';
// Types
import { SelectedRowType } from '../../../types/types';

type Props = {
    filtros: any
    setFiltros: React.Dispatch<React.SetStateAction<any>>
    cargando: boolean
    handleClick: (action: any) => void
    updateList: () => Promise<void>
};

const validationSchema = yup.object({
    hasta: yup.date().max(new Date().toISOString().substring(0, 10), 'Fecha maxima hoy'),
    desde: yup.date().max(yup.ref('hasta'), 'No puede ser superior a la segunda fecha')
});

export default function Filtros({ filtros, setFiltros, cargando, handleClick, updateList }: Props) {
    const { row } = useRecoilValue(rowSelected) as SelectedRowType;

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const data = { desde: { label: 'Desde' }, hasta: { label: 'Hasta' }, estado: { label: 'Estado' }, medioDePago: { label: 'Medio de pago' }};
    const estados = [{ estadoCod: 'todos', estadoNombre: 'Todos' }, { estadoCod: 'X', estadoNombre: 'Rechazados' }, { estadoCod: 'C', estadoNombre: 'Cobrados' }, { estadoCod: 'R', estadoNombre: 'Cancelados' }, { estadoCod: 'P', estadoNombre: 'Pendientes' }];
    const mediosDePago = [{ mpCod: 'todos', mpNombre: 'Todos' }, { mpCod: 'B', mpNombre: 'Transferencia bancaria' }, { mpCod: 'T', mpNombre: 'Tarjeta' }, { mpCod: 'H', mpNombre: 'Dinero en Hooli' }, { mpCod: 'P', mpNombre: 'POS' }];

    const classes = FiltrosStyles();

    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className={classes.filtros}>
                <FormikDateInput
                    data={data}
                    name="desde"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <FormikDateInput
                    data={data}
                    name="hasta"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <FormikSelectInput
                    data={data}
                    name="estado"
                    formik={formik}
                    disabled={cargando}
                    options={estados}
                    optionKey="estadoCod"
                    optionName="estadoNombre"
                    optionValue="estadoCod"
                    className={classes.input}
                />
                <FormikSelectInput
                    data={data}
                    name="medioDePago"
                    formik={formik}
                    disabled={cargando}
                    options={mediosDePago}
                    optionKey="mpCod"
                    optionName="mpNombre"
                    optionValue="mpCod"
                    className={classes.input}
                />
                <div className={classes.marginNormal}>
                    <SubmitButton texto="Filtrar" />
                </div>
            </div>
            <Buttons>
                {row.estado === 'P' ?
                    row.medioDePago === 'B' ?
                        <>
                            <HeaderButton
                                text="Cancelar"
                                icon={eliminar}
                                data={{ action: 'cancelar' }}
                                handleClick={handleClick}
                            />
                            <HeaderButton
                                text="Marcar como cobrado"
                                icon={pesos}
                                data={{ action: 'marcar' }}
                                handleClick={handleClick}
                            />
                        </>
                        :
                        <HeaderButton
                            text="Cancelar"
                            icon={eliminar}
                            data={{ action: 'cancelar' }}
                            handleClick={handleClick}
                        />
                    :
                    <HeaderButton
                        text={"Actualizar pagina"}
                        icon={reload}
                        updateList={updateList}
                    />
                }
            </Buttons>
        </form>
    )
};