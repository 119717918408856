import React from 'react'
import { useStyles } from './styles'
import { Button } from '@mui/material'
export default function ButtonGradient({text, size, color, action,...props}) {
        const styles = useStyles();
    return (
        <div className={styles.root}> 
        <Button onClick={action ? (e) => action(e) : () => {}} className={`${styles.btn} ${size ? styles[size] : styles.normal} ${color ? styles[color] : ""}`} {...props}>{text ? text : ""}</Button>
        </div>
    )
}
