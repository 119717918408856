import axiosPost from '../axiosPost';
import { MerchantUsuarioType, Sucursal, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantSucursalIns = async (arg: UserCredentialsType, data: Sucursal) => {
    const body: AxiosPostBody = {
        "service": "MerchantSucursalIns",
        "params": {
            domicilioCPA: data.domicilioCPA,
            domicilioCalle: data.domicilioCalle,
            domicilioCasa: data.domicilioCasa,
            domicilioLatitud: data.domicilioLatitud,
            domicilioLongitud: data.domicilioLongitud,
            domicilioPiso: data.domicilioPiso,
            domicilioUnidad: data.domicilioUnidad,
            localidadId: Number(data.localidadId),
            merchantId: Number(data.merchantId),
            merchantSucursalNombre: data.merchantSucursalNombre,
            merchantSucursalTelefono: data.merchantSucursalTelefono,
            provinciaId: Number(data.provinciaId),
            registroXUsuarioLatitud: data.registroXUsuarioLatitud,
            registroXUsuarioLongitud: data.registroXUsuarioLongitud,
        }
    }

    try {
        const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        return result
        
    } catch (err) {
        console.log(err)
    }
}