import axiosPost from '../axiosPost';
import { Empleado, MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantEmpleadoIns = async (arg: UserCredentialsType, data: Empleado) => {
    const body: AxiosPostBody = {
        "service": "MerchantEmpleadoIns",
        "params": {
            usuarioNroDeDocumento: data.usuarioNroDeDocumento,
            merchantUsuarioRolId: data.merchantUsuarioRolId,
            merchantId: data.merchantId,
            merchantUsuarioSucursalId: data.merchantUsuarioSucursalId,
            merchantUsuarioFecha: data.merchantUsuarioFecha,
        }
    }

    try {
        const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        return result
        
    } catch (err) {
        console.log(err)
    }
}