import axiosPost from '../axiosPost';
import { Caja, CajaEdit, MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantSucursalCajaUpd = async (arg: UserCredentialsType, data: CajaEdit) => {
    const body: AxiosPostBody = {
        "service": "MerchantSucursalCajaUpd",
        "params": {
            merchantCajaId : data.merchantCajaId,
            merchantCajaNombre: data.merchantCajaNombre,
            merchantCajaNumero: data.merchantCajaNumero,
            merchantCajaVersion: data.merchantCajaVersion,
        }
    }

    try {
        const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        return result
        
    } catch (err) {
        console.log(err)
    }
}