import React from 'react'
import styles from './StylesGeneral.module.scss'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import XGridServer from '../../components/Grid/GridServer/GridServer';
import { userCredentials } from '../../recoilState/GlobalState';
import { useRecoilState } from 'recoil';
import {MerchantEmpleadoGet} from "../../services/hooli-services/Merchants/MerchantEmpleadosGet"
import HeaderButtonTable from '../../components/Button/HeaderButton';
import ModalContainer from './ModalContainer';
import { ProvinciaGet } from '../../services/hooli-services/Provincias/ProvinciasGet';
import { MerchantSucursalGet } from '../../services/hooli-services/Merchants/MerchantSucursalGet';
import { MerchantRolGet } from '../../services/hooli-services/Merchants/MerchantRolGet';

export default function Empleados() {
    const [selectRow, setSelectRow] = useState(null)
    const [sucursalesList, setSucursalesList] = useState([])
    const [empleadosList, setEmpleadosList] = useState([])
    const [rolesList, setRolesList] = useState([])
    const [provincias, setProvincias] = useState(false);
    const [page, setPage] = useState(1)
    const [rowsCount, setRowsCount] = useState(0)
    const [pageSize, setPageSize] = useState(0);
    const [openModal, setOpenModal] = useState(false)
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [ubication, setUbicacion] = useState(null)
    const columns = [
        { field: 'usuarioNombreCompleto', headerName: 'Empleado', flex: 1, minWidth: 100 },
        { field: 'merchantSucursalNombre', headerName: 'Nombre sucursal', flex: 1, minWidth: 100, align: "left", headerAlign: 'left' },
    ];
    const getEmpleados = async (cred) => {
        const data = await MerchantEmpleadoGet(cred, cred.merchantId)
        const sucursales = await MerchantSucursalGet(cred, cred.merchantId)
        const roles = await MerchantRolGet(cred)
        setSucursalesList(sucursales.result)
        setRolesList(roles.result)
        if(data.status.code === 1){
            let formatList = data.result.map((data, i) => ({
                id: data.merchantUsuarioId,
                imagePathPERFIL: data.imagePathPERFIL,
                merchantCUIT: data.merchantCUIT,
                merchantId: data.merchantId,
                merchantNombre: data.merchantNombre,
                merchantSucursalId: data.merchantSucursalId,
                merchantSucursalNombre: data.merchantSucursalNombre,
                merchantUsuarioFecha: data.merchantUsuarioFecha,
                merchantUsuarioId: data.merchantUsuarioId,
                rolCod: data.rolCod,
                rolId: data.rolId,
                rolNombre: data.rolNombre,
                usuarioApellidos: data.usuarioApellidos,
                usuarioCelular: data.usuarioCelular,
                usuarioEmpleadoId: data.usuarioEmpleadoId,
                usuarioMail: data.usuarioMail,
                usuarioNombres: data.usuarioNombres,
                usuarioNroDeDocumento: data.usuarioNroDeDocumento,
                usuarioNombreCompleto: `${data.usuarioNombres} ${data.usuarioApellidos}`,
            }))
            setEmpleadosList(formatList)
        }
    }
    const getProvinicias = async ()=>{
        try{
          const response = await ProvinciaGet(credentials);
          if(response.status.code === 1 && response.result.length > 0) {
            setProvincias(response.result);
          }
          
        }catch(e){
          console.log("Error provinicas get",e)
        }
      }
      
    useEffect(() => {
        if (credentials.merchantId){
            getEmpleados(credentials)
            getProvinicias()
        }
    }, [credentials])
    useEffect(() => {
        if (openModal === false && credentials.merchantId){
            getEmpleados(credentials)
        }
    }, [openModal])
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((data) => {
            setUbicacion({ lat: data.coords.latitude, lng: data.coords.longitude })
        }, (error) => console.log(error))
    }, [])
  return (
    <>
        {openModal && <ModalContainer setSelectRow={setSelectRow} roles={rolesList} sucursales={sucursalesList} selectRow={selectRow} provincias={provincias} ubication={ubication} setModal={setOpenModal} modal={openModal}/>}
        <div className={styles.container}>
            <div className={styles.containerFilters}>
                <div className={styles.containerButtons}>
                    <HeaderButtonTable
                        disabled={selectRow?.status ? true : false}
                        text={"Agregar caja"}
                        link={`autenticaciones`}
                        setModal={setOpenModal}
                        data={{
                            action: "addEmpleados",
                            title: "add",
                            button: "add",
                        }}
                    >
                        <AddRoundedIcon/>
                    </HeaderButtonTable>
                    <HeaderButtonTable
                        disabled={selectRow?.status ? false : true}
                        text={"Editar caja"}
                        link={`autenticaciones`}
                        setModal={setOpenModal}
                        data={{
                            action: "editEmpleados",
                            title: "edit",
                            button: "edit",
                        }}
                    >
                        <EditRoundedIcon/>
                    </HeaderButtonTable>
                    <HeaderButtonTable
                        disabled={selectRow?.status ? false : true}
                        text={"Eliminar caja"}
                        link={`autenticaciones`}
                        setModal={setOpenModal}
                        data={{
                            action: "delEmpleados",
                            title: "del",
                            button: "del",
                        }}
                    >
                        <DeleteOutlineRoundedIcon/>
                    </HeaderButtonTable>
                </div>
            </div>
            <XGridServer columns={columns} setNumberPage={setPage} rowsCount={rowsCount} setPageSize={setPageSize} list={empleadosList} rowSelect={selectRow} setRow={setSelectRow} pageSize={pageSize} onPageSizeChange={setPageSize}/>
        </div>
    </>
    )
}
