import React from "react";
import { Box, Typography } from "@mui/material";
import AngleBrackets from "../../../assets/icons/angle-brackets.png";
import { useStyles } from "./styles";


export const Card = ({image, title, subtitle, opacity,handleClick,merchantId})=> {
    const styles = useStyles();
    const onClick = ()=> {
        if(typeof handleClick === "function"){ 
            return handleClick(merchantId)
        }else {
            return
        }
    }
    return (
        <Box display={"flex"} sx={{cursor:"pointer"}} height={"66px"} width={"540px"} justifyContent={"center"} border={"2px"} boxShadow={"0px 1px 5px 0px rgba(153,153,153,0.7)"} borderRadius={"10px"} onClick={onClick} className={opacity && styles.transparent} > 

            <Box display="flex" alignItems={"center"} marginRight={2} marginLeft={2} width={"50px"}> 
                <img src={image} alt="logo" className={styles.imgComerciosDefault}/>
            </Box>
            
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} width={"80%"}>  
                <Typography variant="h1" className={styles.title}>{title}</Typography>
                <Typography variant="subtitle1" className={styles.subtitle}>{subtitle}</Typography>
            </Box>
            {/* <Box display="flex" alignItems={"center"} marginLeft={2} marginRight={2}> 
            <img src={AngleBrackets} alt="logo" className={styles.imgAngleBrackets}/>
            </Box> */}
           
        </Box>
    )
}