import { Box, Grid, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../components/utils/inputs/FormikInputs.js'
import { useStyles } from './EditSucursalStyles.js'
import Map from '../../components/map/index.jsx'
import { useRecoilValue } from 'recoil'
import { afipData } from '../../recoilState/GlobalState.js'
import axios from 'axios'

export default function EditSucursal({formik, coords, setCoords, provincias}) {
    const recoilAfipData = useRecoilValue(afipData);
    const [localidades, setLocalidades] = useState([]);
    const styles = useStyles();
    const dataFormik = {
        merchantSucursalNombre: {label: "Nombre", placeholder:"Nombre de la sucursal"},
        domicilioCalle: {label: "Calle", placeholder:"Viamonte"},
        domicilioCasa: {label: "Numero", placeholder:"213"},
        domicilioPiso: {label: "Piso", placeholder:"7"},
        domicilioUnidad: {label: "Unidad", placeholder:"Depto."},
        domicilioCPA: {label: "Código Postal (CPA)", placeholder:"C1155ABH"},
        localidadId : {label: "Localidad", placeholder:"Ciudad Autónoma de Buenos Aires"},
        proviciaId: {label: "Provincia", placeholder:"Buenos Aires"},
        merchantSucursalTelefono: {label: "Telefono", placeholder:"114355434543"},
    }
    const getLocalidadesPorProvincia = async(provId) => {
        const {data} = await axios.get("/localidades.json")
      if(provId){
            setLocalidades(data.result.filter((prov) => prov.provinciaId === Number(provId) && prov.localidadId !== 1))
      } 
  }
  const getLocalidad = ()=> { 
    const findLocalidad = localidades.find((loc)=> loc.localidadId === Number(recoilAfipData.domicilioFiscalLocalidadId));
    if(findLocalidad) { 
      return [{localidadNombre: findLocalidad.localidadNombre, localidadId: findLocalidad.localidadId, lat: findLocalidad.localidadGeoPINLatitud, lng: findLocalidad.localidadGeoPINLongitud}]
    }else {
      return false
    }
  }

      useEffect(()=> {
             const loc = {
                 lat: getLocalidad()[0]?.lat || localidades.find((localidad)=> localidad.localidadId === Number(formik.values.localidadId))?.localidadGeoPINLatitud,
                 lng:getLocalidad()[0]?.lng || localidades.find((localidad)=> localidad.localidadId === Number(formik.values.localidadId))?.localidadGeoPINLongitud
             }
             setCoords({
                 lat: loc.lat, 
                 lng: loc.lng
             })
      }, [formik.values.localidadId]);

      useEffect(()=> {
        getLocalidadesPorProvincia(formik.values.proviciaId)
 }, [formik.values.proviciaId]);
 
    useEffect(() => {
    }, [formik.values.provinciaComercial])
    useEffect(() => {
        formik.setFieldValue("domicilioLatitud", coords.lat)
        formik.setFieldValue("domicilioLongitud", coords.lng)
    }, [coords])
    useEffect(() => {
        formik.setFieldValue("localidadId", localidades[0]?.localidadId)
    }, [localidades])

    useEffect(() => {
        getLocalidadesPorProvincia(provincias[0].provinciaId)
    }, [])
  
    return (
    <>
    <Grid container width={"100%"} maxWidth={"800px"} padding={"20px 0 50px 0"}>
        <Grid item width={"100%"} display={"flex"} gap={4}>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"merchantSucursalNombre"} formik={formik} variant="standard"/> 
            <FormikTextInput data={dataFormik} name={"domicilioCalle"} formik={formik} variant="standard" /> 
        </Box>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}>
            <FormikTextInput data={dataFormik} name={"domicilioCasa"} formik={formik} variant="standard"/> 
            <FormikTextInput data={dataFormik} name={"domicilioPiso"} formik={formik} variant="standard"/> 
            <FormikTextInput data={dataFormik} name={"domicilioUnidad"} formik={formik} variant="standard"/> 
            </Box>
        </Grid>
        <Grid item width={"100%"} display={"flex"} gap={4}> 
            <Box display={"flex"} flexDirection={"column"} width={"100%"} className={styles.containerInput}> 
                <FormikTextInput data={dataFormik} name={"domicilioCPA"} formik={formik} />
            </Box>
            <Box display={"flex"} flexDirection={"column"} width={"100%"} className={styles.containerInput}> 
                <FormikTextInput data={dataFormik} name={"merchantSucursalTelefono"} formik={formik} variant="standard"/>
            </Box>
        </Grid>
        <Grid item width={"100%"} display={"flex"} gap={4}> 
            <Box display={"flex"} flexDirection={"column"} width={"100%"} className={styles.containerInput}> 
                <FormikSelectInput data={dataFormik} name={"proviciaId"} formik={formik}  options={provincias} optionName={"provinciaNombre"} optionValue={"provinciaId"} optionKey={"provinciaId"}/>
            </Box>
            <Box display={"flex"} flexDirection={"column"} width={"100%"} className={styles.containerInput}> 
            <FormikSelectInput data={dataFormik} name={"localidadId"} formik={formik} options={localidades} optionKey={"localidadId"} optionValue={"localidadId"} optionName={"localidadNombre"} />
            </Box>
        </Grid>
        <Map lat={coords?.lat} lng={coords?.lng} setCoords={setCoords} />
    </Grid>
    </>
  )
}
