import { Grid, } from '@mui/material'
import React from 'react'
import QRCode from "react-qr-code";

export default function QrCajas({qr}) {
    
    return (
    <>
    <Grid container width={"100%"} maxWidth={"800px"} padding={"20px 0 50px 0"}>
        {qr ? <QRCode value={qr} id='QRCode' /> : "Loading..."}
    </Grid>
    </>
  )
}
