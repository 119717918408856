import React, { useEffect, useRef, useState } from 'react';
import RioNegroLogo from '../../assets/icons/rioNegroLogo.svg';
import LogoQR from '../../assets/icons/QR.png';
import classes from './Login.module.scss';
import QRCode from "react-qr-code";
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
import CommonForm from '../../components/utils/form/CommonForm';
import { LinearProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormikSelectInput } from '../../components/utils/inputs/FormikInputs';
// Services
import { LoginQRGet, LoginQREvent } from '../../services/hooli-services';
import { MerchantUsuarioGet } from '../../services/hooli-services';
// Recoil
import { userCredentials, snackbarData, merchant } from '../../recoilState/GlobalState';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { checkAuth } from '../../services/hooli-services/checkAuth';
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const navigate = useNavigate();

    const [UUID, setUUID] = useState();
    const [loading, setLoading] = useState(false);
    const [expirado, setExpirado] = useState(false);
    const [merchants, setMerchants] = useState([]);
    const [coord, setCoord] = useState({});
    const [progress, setProgress] = useState(0);
    const intentos = useRef(0);

    async function getQR(e) {
        if (e) e.preventDefault();
        setExpirado(false);
        setLoading(true);
        const data = await LoginQRGet({ lat: coord.lat.toFixed(6), lng: coord.lng.toFixed(6) });
        setProgress(0)
        setUUID(data.codigoQR);
        setLoading(false);
    };

    useEffect(() => {
        if (sessionStorage.getItem('hooliToken') && sessionStorage.getItem('sessionId')){
            // const newRoute = (sessionStorage.getItem('rolCod') === 'MD' || sessionStorage.getItem('rolCod') === 'MA') ? '/dashboard' : '/cobros'
            const newRoute = '/listado-comercios'
            return window.location.assign(newRoute)
        } 
        navigator.geolocation.getCurrentPosition((data) => {
            setCoord({ lat: data.coords.latitude, lng: data.coords.longitude })
        }, (error) => setSnackbarInfo({message: 'Error de localización', open: true, severity: 'error'}))
        const timer = setInterval(() => {
            setProgress(oldProgress => oldProgress + 60 / 580 >= 100 ? 100 : oldProgress + 60 / 580 * 100 / 60);
        }, (60 / 580) * 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (coord.lat && coord.lng) getQR()
    }, [coord]);

    useEffect(() => {
        if (UUID) {
            (async function getQREvent() {
                const data = await LoginQREvent({ qr: UUID });
                if (data.status.code === 0) {
                    intentos.current++
                    setUUID(null);
                    if (intentos.current > 2) {
                        setExpirado(true);
                    } else {
                        getQR();
                    }
                }
                if (data.status.code === 1) {   
                    const creds = data.result[0]
                    sessionStorage.setItem('hooliToken', creds.hooliToken);
                    sessionStorage.setItem('sessionId', creds.sessionId);
                    const merchants = await MerchantUsuarioGet(creds);
                    if (merchants.length === 0) {
                        navigate("/no-disponible");
                    }
                    if (merchants.length >= 1) {
                        sessionStorage.setItem('merchants', JSON.stringify(merchants));
                        navigate("/listado-comercios");
                    }
                    setCredentials(creds);
                }
            })()
        }
    }, [UUID]);

  
    // useEffect(() => {
    //     if (Object.keys(credentials).length > 0 && !credentials.hasOwnProperty('merchants')) {
    //         (async function getMerchant() {
    //             const merchants = await MerchantUsuarioGet(credentials);
    //             if (merchants.length === 0) {
    //                     navigate("/no-disponibles");
    //             }
    //             if (merchants.length >= 1) {
    //                 sessionStorage.setItem('merchants', JSON.stringify(merchants));
    //                 navigate("/listado-comercios");
    //             }
    //             // if (merchants.length === 1) {
    //             //     sessionStorage.setItem('merchantId', merchants[0].merchantId);
    //             //     sessionStorage.setItem('merchantRZ', merchants[0].merchantRZ);
    //             //     sessionStorage.setItem('merchantCUIT', merchants[0].merchantCUIT);
    //             //     sessionStorage.setItem('rolCod', merchants[0].rolCod);
    //             //     window.location.assign('/dashboard')
    //             // }
    //         })();
    //     }
    // }, [credentials]);

    return (
        <div className={classes.login}>
            <div className={classes.container}>
                <div className={classes.logoContainer}>
                    <img src={RioNegroLogo} alt="Rio Negro Logo" />
                </div>
                <div className={classes.qrContainer}>
                    {expirado ?
                        <>
                            <h4>Ups!</h4>
                            <p>El código caducó, generá un <span>nuevo QR</span> para poder ingresar.</p>
                            <img src={LogoQR} alt="Celular" />
                            <button className={classes.button} onClick={getQR}>Generar QR</button>
                        </>
                        :
                        <>
                            {!loading ? 
                                <>
                                    <h4>Hola!</h4>
                                    <p>Para poder ingresar, escanea el código QR desde la App de RN</p>
                                </>
                                : null
                            }
                            {UUID && merchants.length === 0 && !loading && <>
                                <QRCode value={UUID} />
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    style={{ width: '100%', marginTop: '16px' }}
                                />
                            </>
                            }
                            {loading && <CircularIndeterminate />}
                        </>}
                </div>
            </div>
        </div>
    )
};