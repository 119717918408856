import { Box, Grid, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../components/utils/inputs/FormikInputs.js'
import { useStyles } from './AddSucursalStyles.js'
import Map from '../../components/map/index.jsx'
import { useRecoilValue } from 'recoil'
import { afipData } from '../../recoilState/GlobalState.js'
import axios from 'axios'

export default function AddEmpleados({formik, genero}) {
    const [step, setStep] = useState(1)
    const styles = useStyles();
    const dataFormik = {
        usuarioNroDeDocumento: {label: "Nro de documento", placeholder:"22222222"},
        usuarioSexo: {label: "Sexo", placeholder:"Nombre"},
    }
  
    return (
    <>
    <Grid container width={"100%"} maxWidth={"800px"} padding={"20px 0 50px 0"}>
        <Grid item width={"100%"} display={"flex"} gap={4}>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"usuarioNroDeDocumento"} formik={formik} variant="standard"/> 
        </Box>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}>
            <FormikSelectInput data={dataFormik} name={"usuarioSexo"} formik={formik} options={genero} optionKey={"value"} optionValue={"value"} optionName={"name"} />
        </Box>
        </Grid>
    </Grid>
    </>
  )
}
