import React, { useState } from 'react';
import { DropZone } from '../../../components/alta-comercios/dropzone';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { afipData, comercioData, userCredentials } from '../../../recoilState/GlobalState';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UrlDocumentosGet } from '../../../services/hooli-services/Documentos/UrlDocumentosGet';
import { DocumentosPut } from '../../../services/hooli-services/Documentos/DocumentosPut';
import { useFormik } from 'formik';
import { FormikDateInput, FormikTextInput } from '../../../components/utils/inputs/FormikInputs';
import { useStyles } from './styles';
import * as yup from "yup";

export function ContainerContratoSocial({nextStep,setStepDocumentos}) {
    const [file,setFile] = useState(null)
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const recoilAfipData = useRecoilValue(afipData);
    const [comercio, setComercioData] = useRecoilState(comercioData);
    const styles = useStyles();
    function formatDate(date) {
      return new Date(date).toLocaleDateString()
    }
    const validationSchema = yup.object({
      fechaInscripcion: yup.date().max(new Date(), 'La fecha debe ser igual o anterior a hoy').required("Debes ingresar una fecha"),
      numeroInscripcion: yup.string().required("Debes ingresar un numero de inscripción"),
  });
    const dataFormik = { 
      fechaInscripcion: {label: "Fecha de inscripción en el registro*", placeholder:"1234"}, 
      numeroInscripcion: {label:"Número de inscripción en el registro*", placeholder:"1234"}, 
      }
      const formik = useFormik({
        initialValues: {
            fechaInscripcion: "",
            numeroInscripcion: "",
        },
        validationSchema: validationSchema,
    }); 
    const urlDoc = async() => {
      // console.log(recoilAfipData.cuit.replaceAll('-', ''))
      const url = await UrlDocumentosGet(credentials, `${recoilAfipData.cuit.replaceAll('-', '')}_CS.pdf`, "CS" ,recoilAfipData.cuit);
      await sendFile(url.result[0].url)
    }
    
    const sendFile = async (url) => {
      // console.log(file[0]) 
      await DocumentosPut(url, file[0])
    }

    const handleSubmit = ()=> {
      setComercioData({
       ...comercio,
        merchantInscripcionFecha: formik.values.fechaInscripcion,
        merchantInscripcionNumero: formik.values.numeroInscripcion,
      })
      urlDoc();
      setStepDocumentos({
        step:6, 
        title: "Designacion de autoridades vigente",
        subtitle: "Subí tu certificado de Autoridades Vigentes."
      })
    }
    console.log(formik.errors, formik.values)
  return (
   <> 
    <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}> 
        <FormikDateInput data={dataFormik} name={"fechaInscripcion"} formik={formik}/>
        <FormikTextInput data={dataFormik} name={"numeroInscripcion"} formik={formik}/>
    </Box>
    <DropZone file={file} setFile={setFile}/>
    <Box> 
      <ButtonGradient size={"medium"} text={"Continuar"} action={handleSubmit} disabled={!file || !formik.isValid}/>
    </Box>
    <Box marginTop={"15px"} marginBottom={"15px"}> 
      <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>setStepDocumentos({
        step:2, 
        title: "Constancia de Inscripción de Ingresos Brutos",
        subtitle: "Podés descargar tu constancia de inscripción desde el sitio web de la AFIP"
      })}/>
    </Box>
   </>
        
    
  )
}

 
