import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((
{
    inputRazonSocial:{ 
        '& .MuiFormControl-root': {
            '& label':{
                color: "#006E54",
                fontSize: "18px",
            },
         
            '& input:focus': {
                '&, &::after' :{
                    borderBottom:"2px solid #006E54 !important", 
                    zIndex: "2"
                },
            }
        }
    },
    containerInput: {
        '& .MuiBox-root': {
            width: "50%",
            '& .MuiFormControl-root': {
                '& label':{
                    color: "#006E54",
                    fontSize: "18px",
                },
             
                '& input:focus': {
                    '&, &::after' :{
                        borderBottom:"2px solid #006E54 !important", 
                        zIndex: "2"
                    },
                }
            }
        }

    }
}
))