import React from 'react';
import { Box, Grid } from '@mui/material';
import {FormikTextInput} from "../../../utils/inputs/FormikInputs";
import { useStyles } from './styles';


function FormContacto({formik}) {
  
    const styles = useStyles();
 
    const dataFormik = { 
    razonSocial: {label: "Razón Social", placeholder:""}, 
    mail: {label:"Mail*", placeholder:"comercio@gmail.com"}, 
    telefono:{label:"Teléfono", placeholder:"2991234567"},
    celular: {label:"Celular*", placeholder:"2991234567"},
    sitioWeb: {label: "Sitio Web", placeholder:"www.comercio.com"},
    instagram: {label: "Instagram", placeholder:"https://www.instagram.com/comercio.ok"},
    facebook: {label:"Facebook", placeholder: "https://www.facebook.com/comercio.ok"},

    }
  return (
    <Grid width={"100%"} padding={"20px 0 50px 0"}>  
        <Box width={"100%"} className={styles.inputRazonSocial}>
            <FormikTextInput data={dataFormik} name={"razonSocial"} formik={formik} disabled={true}/>
        </Box>
        <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"mail"} formik={formik}/>
            <FormikTextInput data={dataFormik} name={"telefono"} formik={formik}/>
        </Box>
        <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"celular"} formik={formik}/>
            <FormikTextInput data={dataFormik} name={"sitioWeb"} formik={formik}/>
        </Box>
        <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"instagram"} formik={formik}/>
            <FormikTextInput data={dataFormik} name={"facebook"} formik={formik}/>
        </Box>
    </Grid>
  
  )
}

export {FormContacto}
