import React from 'react'
import contact from "../../assets/icons/stepper_contacto.svg"
import document from "../../assets/icons/stepper_documentacion_fiscal.svg"
import identification from "../../assets/icons/stepper_identificacion.svg"
import info from "../../assets/icons/stepper_informacion_fiscal.svg"
import localization from "../../assets/icons/stepper_localizacion.svg"
import planes from "../../assets/icons/planes.svg"
import styles from "./Stepper.module.scss"

const stepperArray = [
    {name: "Identificación", img: identification},
    {name: "Contacto", img: contact},
    {name: "Información Fiscal", img: info},
    {name: "Documentación Fiscal", img: document},
    {name: "Localización", img: localization},
    {name: "Planes", img: planes}
]

export default function Stepper({numberStep}) {
    
  return (
    <div className={styles.container}>{stepperArray.map((step, index) => (
        <div className={`${styles.step} ${numberStep === index ? styles.select : numberStep > index ? styles.past : ""}`} key={index}>
            <span className={styles.stepImg}>
                <img src={step.img} alt={step.name}/>
            </span>
            <p className={styles.stepText}>{step.name}</p>
        </div>
    ))}</div>
  )
}
