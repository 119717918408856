import React from 'react'
import style from "./PlanCard.module.scss"
import { FormControlLabel, Radio } from '@mui/material'
import { useStyles } from './styles';

export default function PlanCard({plan}) {
    const styles = useStyles();
  return (
    <div className={style.container}>
        <div className={style.containerLeft}>
            <FormControlLabel control={<Radio color="success" />}value={plan.planCod} />
            <div>
                <h2>{plan.planCod}</h2>
                <p>{plan.planDetallePlazoRemoto/24 === 1 ? `Acreditación en ${plan.planDetallePlazoRemoto/24} día hábil` : `Acreditación en ${plan.planDetallePlazoRemoto/24} días hábiles`}</p>
            </div>
        </div>
        <h3>{`${plan.planDetalleFeeRemoto.toFixed(2)}% + IVA*`}</h3>
    </div>
  )
}
