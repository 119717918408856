import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(()=>(
    {
        containerInput: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
            width: "50%", 
            '& .MuiFormControl-root': {
                '& label':{
                    color: "#006E54",
                    left: "50%",
                    transform: "translate(-50%)",
                    fontSize: "18px"
                   
                },
                '& input::placeholder':{
                    textAlign:"center"
                },
                '& input': {
                    textAlign:"center",
                    width: "300px",
                    fontSize: "22px",
                    paddingTop: "14px",
                },
                '& input:focus': {
                    '&, &::after' :{
                        borderBottom:"2px solid #006E54 !important", 
                        zIndex: "2"
                    },
                },
                // '& MuiInputBase-root.input.Mui-disabled': {
                //     color: "red !important",
                
                // },
            },

            
        }
    }
))