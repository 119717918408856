import React from 'react';
import { FormContacto } from '../../../components/alta-comercios/forms/contacto';
import { Layaout } from '../../../components/layaout';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useRecoilState, useRecoilValue } from 'recoil';
import { afipData, comercioData } from '../../../recoilState/GlobalState';

function ContainerContacto({nextStep}) {

  const validationSchema = yup.object({
    mail: yup.string().required("Debes ingresar tu mail").matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, "Debe ingresar un formato correcto"),
    celular: yup.string().required("Debes ingresar tu celular (10 dígitos)").matches(/^(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/g, "Debe ingresar un formato correcto (10 dígitos)"),
    telefono: yup.string().matches(/^\d{10}$/g, "Debe ingresar un formato correcto (10 dígitos)"),
}); 
 const recoilAfipData = useRecoilValue(afipData);
 const [comercio,setDataComercios] = useRecoilState(comercioData);
 let valueRz = sessionStorage.getItem("afipData") ? JSON.parse(sessionStorage.getItem("afipData")).personaFJ === "J" ? JSON.parse(sessionStorage.getItem("afipData")).merchantRZ : `${JSON.parse(sessionStorage.getItem("afipData")).usuarioApellidos}, ${JSON.parse(sessionStorage.getItem("afipData")).usuarioNombres}` : recoilAfipData.personaFJ === "J" ? recoilAfipData.merchantRZ : `${recoilAfipData.usuarioApellidos}, ${recoilAfipData.usuarioNombres}` ;
  const formik = useFormik({
    initialValues: { 
        razonSocial: valueRz ,
        mail: JSON.parse(sessionStorage.getItem("contactoInfo"))?.merchantMail || "",
        telefono: JSON.parse(sessionStorage.getItem("contactoInfo"))?.merchantTelefono || "",
        celular: JSON.parse(sessionStorage.getItem("contactoInfo"))?.merchantCelular || "",
        sitioWeb: JSON.parse(sessionStorage.getItem("contactoInfo"))?.merchantWebSite ||  "",
        instagram: JSON.parse(sessionStorage.getItem("contactoInfo"))?.merchantInstagram || "",
        facebook: JSON.parse(sessionStorage.getItem("contactoInfo"))?.merchantFacebook || ""
    },
    validationSchema: validationSchema,
    onSubmit: (values)=> { 
      sessionStorage.setItem("contactoInfo", JSON.stringify({ 
        merchantMail: values.mail,
        merchantCelular: values.celular,
        merchantTelefono: values.telefono,
        merchantInstagram: values.instagram,
        merchantWebSite: values.sitioWeb,
        merchantFacebook: values.facebook 
      }))
      setDataComercios({...comercio, 
        merchantMail: values.mail,
        merchantCelular: values.celular,
        merchantTelefono: values.telefono,
        merchantInstagram: values.instagram,
        merchantWebSite: values.sitioWeb,
        merchantFacebook: values.facebook 
      })
      nextStep(2)
    },
    initialErrors:{
      mail:false,
      celular: false
    }
}); 
  return (
    <>   
          <FormContacto formik={formik}/>
          <Box> 
            <ButtonGradient size={"medium"} text={"Continuar"} action={formik.handleSubmit} disabled={!formik.isValid}/>
          </Box>
          <Box marginTop={"15px"} marginBottom={"15px"}> 
          <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>nextStep(0)}/>
        </Box>
    </>
  
  )
}

export { ContainerContacto }