import React from 'react'
import styles from './BigInput.module.scss'

export default function BigInput() {
  return (
    <div className={styles.container}>
        <p>Importe</p>
        <div className={styles.containerInput}>
            <input type="text" placeholder="1000" />
            <p>$</p>
        </div>
    </div>
  )
}
