import React, { useState } from 'react';
import { DropZone } from '../../../components/alta-comercios/dropzone';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { useRecoilState, useRecoilValue } from 'recoil';
import { afipData, comercioData, userCredentials } from '../../../recoilState/GlobalState';
import { UrlDocumentosGet } from '../../../services/hooli-services/Documentos/UrlDocumentosGet';
import { DocumentosPut } from '../../../services/hooli-services/Documentos/DocumentosPut';

export function ContainerConstanciaIngresosBrutos({nextStep, setStepDocumentos, data}) {
    const [file,setFile] = useState(null);
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [comercio,setDataComercios] = useRecoilState(comercioData);
    const recoilAfipData = useRecoilValue(afipData);
    const urlDoc = async() => {
      // console.log(recoilAfipData.cuit.replaceAll('-', ''))
      const url = await UrlDocumentosGet(credentials, `${recoilAfipData.cuit.replaceAll('-', '')}_IIBB.pdf`, "IIBB" ,recoilAfipData.cuit);
      await sendFile(url.result[0].url)
    }
    
    const sendFile = async (url) => {
      // console.log(file[0]) 
      await DocumentosPut(url, file[0])
    }

    const handleSubmit = ()=> {
      urlDoc();
      if(data.merchantSO === "Y"){ 
        setStepDocumentos({
          step:3,
          title: "Constancia de Inscripción de Sujetos Obligados ante UIF",
          subtitle:"Si entas dentro de la categoria, subí tu contrato de inscripción ante UIF."
        })
      } else {
        setDataComercios({...comercio, documentosSOCantidad: 0});
        setStepDocumentos({
          step:4,
          title: "Otros documentos",
          subtitle:"Subí otros documentos como tu Constancia de exención a Ingresos Brutos"
        })
      }
    }
  return (
   <> 
    <DropZone file={file} setFile={setFile}/>
    <Box> 
            <ButtonGradient size={"medium"} text={"Continuar"} action={handleSubmit} disabled={!file}/>
          </Box>
          <Box marginTop={"15px"} marginBottom={"15px"}> 
          <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>setStepDocumentos({
           step: 1, 
           title: "Constancia de Inscripción de AFIP",
           subtitle: "Podés descargar tu constancia de inscripcion desde el sitio web de la AFIP"
        })}/>
        </Box>
   </>
        
    
  )
}

 
