import axiosPost from '../axiosPost';
import { ComercioType, MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantOnboardDo = async (arg: UserCredentialsType, data: ComercioType) => {
    const body: AxiosPostBody = {
        "service": "MerchantOnboardDo",
        "params": {
            actividadAFIPCod: Number(data.actividadAFIPCod),
            cargoId: Number(data.cargoId),
            condicionAFIPCod: data.condicionAFIPCod,
            documentosAccionistasCantidad: data.documentosAccionistasCantidad,
            documentosApoderadosCantidad: data.documentosApoderadosCantidad,
            documentosAutoridadesCantidad: data.documentosAutoridadesCantidad,
            documentosOtrosCantidad: data.documentosOtrosCantidad,
            domicilioComercialCPA: data.domicilioComercialCPA,
            domicilioComercialCalle: data.domicilioComercialCalle,
            domicilioComercialCasa: data.domicilioComercialCasa,
            domicilioComercialLatitud: data.domicilioComercialLatitud,
            domicilioComercialLocalidadId: Number(data.domicilioComercialLocalidadId),
            domicilioComercialLongitud: data.domicilioComercialLongitud,
            domicilioComercialPiso: data.domicilioComercialPiso,
            domicilioComercialUnidad: data.domicilioComercialUnidad,
            domicilioFiscalCPA: data.domicilioFiscalCPA,
            domicilioFiscalCalle: data.domicilioFiscalCalle,
            domicilioFiscalCasa: data.domicilioFiscalCasa,
            domicilioFiscalLatitud: data.domicilioFiscalLatitud,
            domicilioFiscalLongitud: data.domicilioFiscalLongitud,
            domicilioFiscalPiso: data.domicilioFiscalPiso,
            domicilioFiscalUnidad: data.domicilioFiscalUnidad,
            domicilioFiscalLocalidadId: Number(data.domicioFiscalLocalidadId),
            merchantCUIT: data.merchantCUIT,
            merchantCelular: data.merchantCelular,
            merchantCombustible: data.merchantCombustible,
            merchantFacebook: data.merchantFacebook,
            merchantIBCod: data.merchantIBCode,
            merchantIBNumero: data.merchantIBNumero,
            merchantIBProvinciaId: data.merchantIBProvinciaId,
            merchantInstagram: data.merchantInstagram,
            merchantMail: data.merchantMail,
            merchantMicroempresa: data.merchantMicroempresa,
            merchantNoResidente: data.merchantNoResidente,
            merchantNoResidenteCuenta: data.merchantNoResidenteCuenta,
            merchantNoResidenteTC: data.merchantNoResidenteTC,
            merchantNombre: data.merchantNombre,
            merchantPEP: data.merchantPEP,
            merchantPFPJ: data.merchantPFPJ,
            merchantRZ: data.merchantRZ,
            merchantRetieneIB: data.merchantRetieneIB,
            merchantRetieneIBAlicuota: Number(data.merchantRetieneIBAlicuota),
            merchantRetieneIG: data.merchantRetieneIG,
            merchantRetieneIVA: data.merchantRetieneIVA,
            merchantSO: data.merchantSO,
            merchantTelefono: data.merchantTelefono,
            merchantWebSite: data.merchantWebSite,
            paisId: Number(data.paisId),
            planCod: data.planCod,
            rubroDeMerchantId:Number(data.rubroDeMerchantId),
            tipoSocietarioId:data.tipoSocietarioId === null ? null : Number(data.tipoSocietarioId),
            beneficiarios: data.beneficiarios,
            merchantCash: data.merchantCash,
            merchantInscripcionFecha: data.merchantInscripcionFecha,
            merchantInscripcionNumero: data.merchantInscripcionNumero,
            documentosBalanceCantidad: data.documentosBalanceCantidad,
            documentosBalanceCertificacionCantidad: data.documentosBalanceCertificacionCantidad,
            merchantOrigenCod: "RNEGRO"
        }
    }

    try {
        const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        return result
        
    } catch (err) {
        console.log(err)
    }
}