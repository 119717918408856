import React, { useState } from 'react';
import styles from "./Beneficiarios.module.scss"
import { Box, Checkbox, Grid } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { afipData, comercioData, userCredentials } from '../../../recoilState/GlobalState';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UrlDocumentosGet } from '../../../services/hooli-services/Documentos/UrlDocumentosGet';
import { UrlDocumentosDel } from '../../../services/hooli-services/Documentos/DocumentosDel';
import { DocumentosPut } from '../../../services/hooli-services/Documentos/DocumentosPut';
import XGridServer from '../../../components/Grid/GridServer/GridServer';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { FormikTextInput } from '../../../components/utils/inputs/FormikInputs';
import { useFormik } from 'formik';
import { DropZone } from '../../../components/alta-comercios/dropzone';
import * as yup from "yup";

export default function Beneficiarios({ nextStep, setStepDocumentos }) {
    const [checked, setChecked] = useState(false)
    const [selectRow, setSelectRow] = useState(null)
    const [beneficiariosList, setBeneficiariosList] = useState([])
    const [file, setFile] = useState([])
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const recoilAfipData = useRecoilValue(afipData);
    const [rowsCount, setRowsCount] = useState(0)
    const [update, setUpdate] = useState([])
    const [pageSize, setPageSize] = useState(0);
    const [comercio, setDataComercios] = useRecoilState(comercioData);
    const [modal, setModal] = useState(false)
    const [page, setPage] = useState(1)
    let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
    const validationSchema = yup.object({
        documento: yup.number().min(10000, "Debe tener mas de 5 digitos").max(999999999, "Debe tener menos de 9 digitos").required("Debes ingresar su numero de documento"),
        porcentaje: yup.number("Debe ser un numero").test(
                "is-decimal",
                "Debe ingresar maximo dos decimales",
                (val) => {
                  if (val != undefined) {
                    return patternTwoDigisAfterComma.test(val);
                  }
                  return true;
                }
              ).max(100, "Debe ser un valor menor o igual a 100%").min(10, "Debe ser un valor mayor a 10%").required("Debes ingresar un porcentaje"),
    });
    const columns = [
        { field: 'beneficiarioNroDeDocumento', headerName: 'Nro Documento', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
        { field: 'beneficiarioParticipacion', headerName: 'Porcentaje de participación', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
        { field: 'beneficiarioEsDirecto', headerName: 'Beneficiario indirecto', flex: 1, minWidth: 100, align: "center", headerAlign: 'center' },
    ];
    const formik = useFormik({
        initialValues: { 
            documento:"",
            porcentaje:"",
            participacionIndirecta: false
        },
        onSubmit: (values)=> { 
            console.log(values)
        },
        validationSchema: validationSchema,
        initialErrors:{
            documento:false,
            porcentaje:false,
            participacionIndirecta:false
        }
    }); 
    const dataFormik = { 
        documento: {label:"Nro de documento*", placeholder:"22222222"},
        porcentaje: {label:"Porcentaje de participación*", placeholder:"10%"},
    }
    const urlDoc = async () => {
        await Promise.all(file.map(async (archive, i) => {
            const url = await UrlDocumentosGet(credentials, `${archive.name}`, "BEN", recoilAfipData.cuit, formik.values.documento);
            await sendFile(url.result[0].url, archive);
            setUpdate([...update, i])
        }));
        // console.log(recoilAfipData.cuit.replaceAll('-', ''))
    }
    const sendFile = async (url, archive) => {
        // console.log(archive) 
        await DocumentosPut(url, archive)
    }
    const handleChange = (e) => {
        if(beneficiariosList.length === 0){
            setChecked(true)
        }
        if(checked){
            setChecked(false)
        }
    } 
    const handleChangeParticipacionDirecta = (e) => {
        formik.setFieldValue('participacionIndirecta', e.target.checked)
    } 
    const handleSubmit = async () => {
        console.log(beneficiariosList)
        setDataComercios({...comercio, beneficiarios: beneficiariosList});
        nextStep(4)
    }
    const closeModal = () => {
        setModal(false)
        setFile([])
        formik.resetForm()
    }
    const handleSubmitBeneficiario= () => {
        let porcentaje = 0
        for (const benef of beneficiariosList) {
            porcentaje += parseInt(benef.beneficiarioParticipacion)
            if(benef.beneficiarioNroDeDocumento === formik.values.documento){
                formik.setFieldError('documento', 'El documento ya existe')
                return
            }
        }
        console.log(porcentaje + formik.values.porcentajeentaje)
        if(porcentaje + parseInt(formik.values.porcentaje) > 100){
            formik.setFieldError('porcentaje', 'El porcentaje total deben ser menor a 100%')
            return
        }
        urlDoc()
        const beneficiario = {beneficiarioNroDeDocumento: formik.values.documento, beneficiarioEsDirecto: !formik.values.participacionIndirecta ? "Y" : "N", beneficiarioParticipacion: formik.values.porcentaje, id: formik.values.documento, documentosBeneficiarioCantidad: file.length }
        setBeneficiariosList([...beneficiariosList, beneficiario])
        formik.handleReset()
        setFile([])
        setModal(false)
    }
    const handleDeleteBeneficiario = async(beneficiario) => {
        if(beneficiario){
            const del = await UrlDocumentosDel(credentials, recoilAfipData.cuit, beneficiario.id);
            setBeneficiariosList(beneficiariosList.filter(benef => benef.id!== beneficiario.id))
        }
    }
    console.log(beneficiariosList)
    return (
        <>
            <Box display={"flex"} justifyContent={"end"} width={"100%"} gap={2} marginBlock={"10px"}>
                <div className={styles.button} onClick={() => setModal(true)}><AddRoundedIcon />Agregar</div>
            </Box>
            <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={2} marginBlock={"10px"}>
                <div className={styles.containerTable}>
                    <div className={styles.headerTable}>
                        <div className={styles.titleTable}><p>Nro Documento</p></div>
                        <div className={styles.titleTable}><p>Porcentaje de participación</p></div>
                        <div className={styles.titleTable}><p>Beneficiario Indirecto</p></div>
                        <div className={styles.deleteBtn}></div>
                    </div>
                    <div className={styles.bodyTable}>
                        {beneficiariosList.map((beneficiario, i) => (
                            <div className={styles.rowTable} key={i}>
                                <div className={styles.cellTable}>{beneficiario.beneficiarioNroDeDocumento}</div>
                                <div className={styles.cellTable}>{beneficiario.beneficiarioParticipacion}%</div>
                                <div className={styles.cellTable}>{beneficiario.beneficiarioEsDirecto === "Y" ? "Si" : "No"}</div>
                                <div className={styles.deleteBtn} onClick={() => handleDeleteBeneficiario(beneficiario)}><DeleteOutlineRoundedIcon /></div>
                            </div>
                        ))}
                    </div>
                </div>
            </Box>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    color="success" 
                />
                <p style={{marginLeft:"10px", color:"#8A8A8A", fontSize:"16px"}}>Ningún beneficiario tiene una participación mayor al 10%</p>
            </Box>
            <Box marginTop={"24px"}>
                <ButtonGradient size={"medium"} text={"Continuar"} action={handleSubmit} disabled={beneficiariosList.length === 0 && !checked} />
            </Box>
            <Box marginTop={"15px"} marginBottom={"15px"}>
                <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={() => setStepDocumentos({
                    step: 9,
                    title: "Acta de aprobación de balance",
                    subtitle: "Subí tu Acta de aprobación de balance"
                })} />
            </Box>
            {modal && <div className={styles.modalContainer}>
                <div className={styles.modal}>
                <div className={styles.header}>Agregar beneficiarios principales</div>
                <Grid width={"100%"} display={"flex"} gap={4} padding={"20px 0 0"}>
                    <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}> 
                        <FormikTextInput data={dataFormik} name={"documento"} formik={formik} className={styles.input}/>
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}>
                        <FormikTextInput data={dataFormik} name={"porcentaje"} formik={formik} className={styles.input}/>
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={4} marginTop={"16px"} marginBottom={"8px"} className={styles.containerInput}>
                    <Box display={"flex"} flexDirection={"column"} alignItems={"start"} justifyContent={"center"}>
                        <p style={{color:"rgba(0, 0, 0, 0.6)", fontSize:"12px", fontWeight:"600"}}>Participación indirecta</p>
                        <Checkbox
                            checked={formik.values.participacionIndirecta}
                            onChange={handleChangeParticipacionDirecta}
                            color="success" 
                        />
                    </Box>
                    </Box>
                </Grid>
                <Grid width={"50%"} padding={"20px 0 0"}>
                <div className={styles.txt}><span>Documento de Identidad</span>(Subí la imagen de tu DNI, frente y dorso)</div>
                    <DropZone file={file} setFile={setFile} horizontal={true} modal={true} allFiles={true} />
                </Grid>
                <Grid marginBlock={"18px"} display={"flex"} gap={4} justifyContent={"center"}>
                    <Box>
                        <ButtonGradient size={"medium"} text={"Cancelar"} color="transparent" action={() => closeModal()} />
                    </Box>
                    <Box>
                        <ButtonGradient size={"medium"} text={"Agregar"} action={handleSubmitBeneficiario} disabled={!formik.isValid || file.length === 0} />
                    </Box>
                </Grid>
                </div>
            </div>}
        </>
    )
}
