import axiosPost from '../axiosPost';
import { AxiosPostBody } from '../../../types/AxiosPost';
import { UserCredentialsType, UsuarioType } from '../../../types/types';
import axiosPostMia from '../axiosPostMia';

type Args = UserCredentialsType & {
    dni: string,
    sexo: string,
};

export const usuarioBasicosGet = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "UsuarioBasicosGet",
        "params": {
            "usuarioNroDeDocumento": arg.dni,
            "usuarioSexo": arg.sexo,
        }
    }

    const result = await axiosPostMia<UsuarioType>('Y', body, arg);
    return result.result
}