
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(()=> ({
    containerInput: {
        marginBlock: "4px",
        display: "flex",
        justifyContent: "start",
        '& img': {
            filter: "brightness(0) saturate(100%) invert(24%) sepia(69%) saturate(1746%) hue-rotate(142deg) brightness(95%) contrast(101%)",
        },
        '& .MuiFormControl-root':{
            marginTop: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            '& .MuiFormLabel-root': {
                color: "#006E54",
                fontSize: "18px",
            },
            '& >div': {
                cursor: "pointer",
                display: "flex",
                '& section':{
                    display: "none",
                },
                '& :hover section': {
                    color: "#000",
                    display: "flex",
                    position: "absolute",
                    padding: "8px",
                    backgroundColor: "#eee",
                    zIndex: "9999",
                    top: "0",
                    borderRadius: "10px",
                    width: "250px",
                    left: "0",
                }
            },
            
            '& .MuiFormGroup-root': {
                display: "flex",
                flexDirection: "row",
                marginLeft: "15px",
                '& .MuiFormControlLabel-root': {
                    flexDirection: "row-reverse",
                    color: "#707070",
                    '& .MuiButtonBase-root': {
                        color: "#006E54"
                    }
                }
            }
        }
    },
    containerActividadFiscal: { 
        '& .MuiInputBase-root': {
            marginTop:"0px",    
            borderTop: "1px solid rgba(0, 0, 0, 0.42)",
            borderLeft: "1px solid rgba(0, 0, 0, 0.42)",
            borderRight:"1px solid rgba(0, 0, 0, 0.42)",
            borderRadius:"10px",
            '&, &::before': { 
                borderRadius:"10px",
            } 
        },
        '& .MuiFormControl-root': {
            '& label':{
                position:"sticky",
                color: "#006E54",
                fontSize: "12px",
            },
            '& input': { 
               textAlign:"start",
                height: "90px",
                fontSize: "18px",
            },

            '& input:focus': {
                 '&, &::after' :{
                     borderBottom:"1px solid #006E54 !important", 
                     borderRight:"1px solid #006E54 !important", 
                     borderTop:"1px solid #006E54 !important", 
                     borderLeft: "1px solid #006E54 !important",
                     borderRadius:"10px",
                     zIndex: "2",
                 },
            },
            '& legend': {
                display:"none"
            }
     }
    },
    containerRadio: {
        '& .MuiFormControl-root':{
            '& .MuiFormLabel-root': {
                color: "#006E54",
                fontSize: "14px",
            },}}
}))