import { Button } from '@mui/material';
import React from 'react';
import { SubmitButtonStyles } from './SubmitButton.styles';

export default function SubmitButton({ texto }) {
    const classes = SubmitButtonStyles();
    return (
        <Button type="submit" className={classes.root} sx={{color:"white", borderRadius:"10px", fontWeight:300}} >
            {texto}
        </ Button>
    )
};