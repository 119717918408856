import axiosPost from '../axiosPost';
import { MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';
import axiosPostMia from '../axiosPostMia';

export const MerchantRolGet = async (arg: UserCredentialsType) => {
    const body: AxiosPostBody = {
        "service": "MerchantRolGet",
        "params": {
        }
    }

    try {
        const result = await axiosPostMia<MerchantUsuarioType>('Y', body, arg);
        return result
        
    } catch (err) {
        console.log(err)
    }
}