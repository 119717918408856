import React from 'react'
import styles from './Cobros.module.scss'
import BigInput from '../../components/bigInput'
import { Box } from '@mui/material'
import { useFormik } from 'formik';
import { FormikTextInput } from '../../components/utils/inputs/FormikInputs';
// import HeaderButton from "../UI/Button/HeaderButton";
// import Buttons from "../UI/ButtonsContainer/Buttons";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import PointOfSaleRoundedIcon from '@mui/icons-material/PointOfSaleRounded';
// import { ACTIONS } from "../../const/Actions";

// import styles from "./Cobros.module.scss"
import { useContext, useEffect, useState } from "react";
// import { Context } from "../../context/Context";
// import { getUser } from "../../Services/getUser";
// import ModalContainer from "../Modal/Modal";
import { useNavigate } from "react-router-dom";
import XGridServer from '../../components/Grid/GridServer/GridServer';
import { userCredentials } from '../../recoilState/GlobalState';
import { useRecoilState } from 'recoil';
import {MerchantSucursalGet} from "../../services/hooli-services/Merchants/MerchantSucursalGet"
import HeaderButtonTable from '../../components/Button/HeaderButton';
import ModalContainer from './ModalContainer';
import axios from 'axios';
import { ProvinciaGet } from '../../services/hooli-services/Provincias/ProvinciasGet';
import ButtonGradient from '../../components/utils/button/ButtonGradient';

const pago1 = [
  {
    id: 1,
    nombre: '1 pago de 1400'
  },
]
const cuotas = [
  {
    id: 1,
    nombre: '3 cuotas de $466,66',
    subtitle: 'Sin interes'
  },
  {
    id: 2,
    nombre: '6 cuotas de $233,33',
    subtitle: 'Sin interes'
  },
  {
    id: 3,
    nombre: '12 cuotas de $116,66',
    subtitle: 'Sin interes'
  }
]
const plan = [
  {
    id: 1,
    nombre: '12 cuotas fijas de $136,82',
    subtitle: 'Precio financiado: $1641,92',
  }
]

export default function Cobros() {
//   // const {selectRow, setSelectRow, setDetailRow} = useContext(Context);
//     const [selectRow, setSelectRow] = useState(null)
//     const [detailRow, setDetailRow] = useState(null)
//     const [sucursalesList, setSucursalesList] = useState([])
//     const [provincias, setProvincias] = useState(false);
//     const [page, setPage] = useState(1)
//     const [rowsCount, setRowsCount] = useState(0)
//     const [pageSize, setPageSize] = useState(0);
//     const [openModal, setOpenModal] = useState(false)
//     const [credentials, setCredentials] = useRecoilState(userCredentials);
//     const [ubication, setUbicacion] = useState(null)
//     const navigate = useNavigate()
//     const urlParam = sessionStorage.getItem("tenantId")
//     const columns = [
//         { field: 'sucursalNombre', headerName: 'Nombre sucursal', flex: 1, minWidth: 100 },
//         { field: 'sucursalDireccion', headerName: 'Dirección', flex: 1, minWidth: 100, align: "left", headerAlign: 'left' },
//     ];
//     const getSucursales = async (cred) => {
//         const data = await MerchantSucursalGet(cred, cred.merchantId)
//         console.log("data", data)
//         if(data.status.code === 1){
//             let formatList = data.result.map((data, i) => ({
//                 id: data.merchantSucursalId,
//                 sucursalId: data.merchantSucursalId,
//                 sucursalNombre: data.merchantSucursalNombre,
//                 sucursalDireccion: `${data.domicilioCalle} ${data.domicilioCasa}`,
//                 domicilioCPA: data.domicilioCPA,
//                 domicilioCalle: data.domicilioCalle,
//                 domicilioCasa: data.domicilioCasa,
//                 domicilioLatitud: data.domicilioLatitud,
//                 domicilioLongitud: data.domicilioLongitud,
//                 domicilioPiso: data.domicilioPiso,
//                 domicilioUnidad: data.domicilioUnidad,
//                 localidadId: data.localidadId,
//                 localidadNombre: data.localidadNombre,
//                 merchantId: data.merchantId,
//                 merchantNombre: data.merchantNombre,
//                 merchantPlanDescuento: data.merchantPlanDescuento,
//                 merchantSucursalEsCasaCentral: data.merchantSucursalEsCasaCentral,
//                 merchantSucursalVersion: data.merchantSucursalVersion,
//                 merchantSucursalId: data.merchantSucursalId,
//                 merchantSucursalNombre: data.merchantSucursalNombre,
//                 merchantSucursalTelefono: data.merchantSucursalTelefono,
//                 provinciaNombre: data.provinciaNombre,
//             }))
//             setSucursalesList(formatList)
//         }
//     }
//     const getProvinicias = async ()=>{
//         try{
//           const response = await ProvinciaGet(credentials);
//           if(response.status.code === 1 && response.result.length > 0) {
//             setProvincias(response.result);
//           }
          
//         }catch(e){
//           console.log("Error provinicas get",e)
//         }
//       }
      
//     useEffect(() => {
//         if (credentials.merchantId){
//             getSucursales(credentials)
//             getProvinicias()
//         }
//     }, [credentials])
//     useEffect(() => {
//         if (openModal === false && credentials.merchantId){
//             getSucursales(credentials)
//         }
//     }, [openModal])
//     useEffect(() => {
//         navigator.geolocation.getCurrentPosition((data) => {
//             setUbicacion({ lat: data.coords.latitude, lng: data.coords.longitude })
//         }, (error) => console.log(error))
//     }, [])
//     console.log(ubication)
    // useEffect(() => {
    //     (async () => {
    //         const user = search ? await getUser(Number(urlParam) ,search, null, pageSize) : await getUser(Number(urlParam) ,null, page, pageSize)
    //         setRowsCount(user.status.id)
    //         if (user) {
    //             let formatList = user.result.map((data, i) => ({
    //                 id: data.usuarioId,
    //                 usuarioId: data.usuarioId,
    //                 usuarioNombre: data.usuarioNombre,
    //                 tenantId: data.tenantId,
    //                 tenantNombre: data.tenantNombre,
    //                 usuarioEVANId: data.usuarioEVANId,
    //                 registracionPrimera: data.registracionPrimera !== "" ? `${new Date(data?.registracionPrimera).toLocaleDateString("es-ES")} ${new Date(data.registracionPrimera).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}` : "-",
    //                 registracionUltima: data.registracionUltima !== "" ? `${new Date(data?.registracionUltima).toLocaleDateString("es-ES")} ${new Date(data.registracionUltima).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}` : "-",
    //                 registracionCantidad: data.registracionCantidad,
    //                 autenticacionUltima: data.autenticacionUltima !== "" ? `${new Date(data?.autenticacionUltima).toLocaleDateString("es-ES")} ${new Date(data.autenticacionUltima).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}` : "-",
    //                 autenticacionCantidad: data.autenticacionCantidad
    //             }))
    //             setUserList(formatList)
    //         }
    //     })();
    // }, [search, page, pageSize]);
  const formik = useFormik({
    initialValues: {
        dni:"",
        factura: "",
        concepto: "",
    },
    // validationSchema: validationSchema,
    onSubmit: (values)=> { 
       console.log(values)
    }
  }); 
  const dataFormik = { 
    dni: {label: "DNI del cliente", placeholder:""}, 
    factura: {label:"Numero de factura (opcional)", placeholder:""}, 
    concepto:{label:"Concepto (opcional)", placeholder:""},
    }
//   console.log(openModal)
  return (
    // Page 1
    // <>
    // <div className={styles.containerHeader}>
    //   <div className={styles.containerCaja}>
    //     <div>
    //     <p>Sucursal San Isidro</p>
    //     <p>Caja 1</p>
    //   </div>
    //   <div>
    //     <PointOfSaleRoundedIcon/>
    //   </div>
    // </div>
    // </div>
    // <section className={styles.container}>
    //   <h1>Hola!</h1>
    //   <p>Vas a realizar un cobro presencial, selecciona la opcion del menu para comenzar.</p>
    //   <div className={styles.containerCard}>
    //     <div className={styles.containerCredit}><CreditCardRoundedIcon/></div>
    //     <p>Cobrar con tarjeta debito/credito</p>
    //     <div className={styles.containerArrow}><ChevronRightRoundedIcon/></div>
    //   </div>
    // </section>
    // </>
    // page 2
    <>
    <div className={styles.containerHeader}>
       <div className={styles.containerCaja}>
         <div>
         <p>Sucursal San Isidro</p>
         <p>Caja 1</p>
       </div>
       <div>
         <PointOfSaleRoundedIcon/>
       </div>
     </div>
     </div>
    <section className={styles.container}>
      <BigInput/>
      <div className={styles.containerFormasPago}>
        {pago1.length > 0 && <div className={styles.containerCards}>
          {pago1.map(item => (
            <div key={item.id} className={styles.containerCard}>
              <p className={styles.titleCard}>{item.nombre}</p>
              <p className={styles.subtitleCard}>{item.subtitle}</p>
            </div>
          ))}
          </div>}
          {cuotas.length > 0 && <div className={styles.containerCards}>
          <div className={`${styles.containerCard} ${styles.cardTitle}`}>
            <p>Pago en cuotas</p>  
          </div>
          {cuotas.map(item => (
            <div key={item.id} className={styles.containerCard}>
              <p className={styles.titleCard}>{item.nombre}</p>
              <p className={styles.subtitleCard}>{item.subtitle}</p>
            </div>
          ))}
          </div>}
          {plan.length > 0 && <div className={styles.containerCards}>
          <div className={`${styles.containerCard} ${styles.cardTitle}`}>
            <p>Plan ahora 12</p>  
          </div>
          {plan.map(item => (
            <div key={item.id} className={styles.containerCard}>
              <p className={styles.titleCard}>{item.nombre}</p>
              <p className={styles.subtitleCard}>{item.subtitle}</p>
            </div>
          ))}
          </div>}
      </div>
    </section>
    <div className={styles.containerBtns}>
      <ButtonGradient text={"Continuar"} size={"medium"}/>
      <ButtonGradient size={"medium"} text={"Volver"} color="transparent"/>
    </div>
    </>
    // page 3
  //   <>
  //   <div className={styles.containerHeader}>
  //      <div className={styles.containerCaja}>
  //        <div>
  //        <p>Sucursal San Isidro</p>
  //        <p>Caja 1</p>
  //      </div>
  //      <div>
  //        <PointOfSaleRoundedIcon/>
  //      </div>
  //    </div>
  //    </div>
  //    <section className={styles.container}>
  //   <div className={styles.containerForm}>
  //     <Box display={"flex"} flexDirection={"column"} gap={4} width={"100%"}>
  //       <FormikTextInput data={dataFormik} name={"dni"} formik={formik} disabled={false}/>
  //       <FormikTextInput data={dataFormik} name={"factura"} formik={formik} disabled={false}/>
  //       <FormikTextInput data={dataFormik} name={"concepto"} formik={formik} disabled={false}/>
  //     </Box>
  //   </div>
  // </section>
  //   <div className={styles.containerBtns}>
  //     <ButtonGradient text={"Continuar"} size={"medium"}/>
  //     <ButtonGradient size={"medium"} text={"Volver"} color="transparent"/>
  //   </div>
  //   </>
    // <div>Cobros</div>
    )
}
