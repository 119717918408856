import React, { useEffect, useState } from 'react';
import { FormInformacionFiscal2 } from '../../../components/alta-comercios/forms/informacion-fiscal2';
import { FormInformacionFiscal } from '../../../components/alta-comercios/forms/informacion-fiscal';
import { FormInformacionFiscal3 } from '../../../components/alta-comercios/forms/informacion-fiscal3';
import { useFormik } from 'formik';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import * as yup from "yup";
import { useRecoilState, useRecoilValue } from 'recoil';
import { afipData, comercioData, userCredentials, usuarioAdicionalData } from '../../../recoilState/GlobalState';
import {UsuarioAdicionalGet} from "../../../services/hooli-services/UsuarioAdicional/UsuarioAdicionalGet";
import {PaisGet} from "../../../services/hooli-services/Pais/PaisGet";


function ContainerInformacionFiscal({nextStep, stepInfoFiscal, setStepInfoFiscal}) {
    const [usuarioAdicional, setUsuarioAdicional] = useRecoilState(usuarioAdicionalData)
    const recoilAfipData = useRecoilValue(afipData);
    const [comercio, setComercio] = useRecoilState(comercioData);
    const [paises,setPaises] = useState([]);
    const credentials = useRecoilValue(userCredentials);
    let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

    const getPais = async ()=> {
        const response = await PaisGet(credentials);
        if(response.status.code === 1){ 
            setPaises(response.result.filter(p => p.paisEsArgentina !== "Y"));
        }
    }

    useEffect(()=> {
        getPais();
    },[]);

    const validationSchema = yup.object({
        regimenIngresosBrutos: yup.string().required("Se debe ingresar regimen de ingresos brutos"),
        provinciaIngresosBrutos: yup.object().shape({
            provinciaId: yup.string().required(),
            provinciaNombre: yup.string().required()
        }).nullable(),
        numeroIngresosBrutos: yup.string().required("Se debe ingresar ingresos brutos")
    })
    const validationSchema2 = yup.object({
        iva: yup.boolean().required("Debes seleccionar una opción"),
        ganancias: yup.boolean().required("Debes seleccionar una opción"),
        ingresosBrutos: yup.boolean().required("Debes seleccionar una opción"),
        alicuota:  yup.number("Debe ser un numero").nullable(true).when("ingresosBrutos", {
            is: true,
            then: yup.number("Debe ser un numero").test(
                "is-decimal",
                "Debe ingresar maximo dos decimales",
                (val) => {
                  if (val != undefined) {
                    return patternTwoDigisAfterComma.test(val);
                  }
                  return true;
                }
              ).max(100, "Debe ser un valor menor a 100").min(0.01, "Debe ser mayor a 0").typeError("Debe ser un numero con separador decimal punto").nullable(true).required("Debes ingresar una alicuota")
          }),
        combustible: yup.boolean().required("Debes seleccionar una opción"),
        microempresas: yup.boolean().required("Debes seleccionar una opción"),
    })
    const validationSchema3 = yup.object({
        sujetoObligado: yup.boolean().required("Debes seleccionar una opción"),
        politicamenteExpuesto: yup.boolean().required("Debes seleccionar una opción"),
        inversorNoResidenteFiscal: yup.boolean().required("Debes seleccionar una opción"),
        paisResidencia:  yup.number().when("inversorNoResidenteFiscal", {
            is: true,
            then: yup.number().required("Debes ingresar un pais")
          }),
          tinNif:yup.string().nullable(true).when("inversorNoResidenteFiscal", {
            is: true,
            then: yup.string().required("Debes ingresar un TIN/NIF")
          }),
          numeroTinNif: yup.number().nullable(true).when("inversorNoResidenteFiscal", {
            is: true,
            then: yup.number().min(1000, "Debe ser mayor a 4 digitos").required("Debes ingresar un número")
          }),
    
    })

    let valueIB = sessionStorage.getItem("afipData") ? JSON.parse(sessionStorage.getItem("afipData"))?.IBCondicion === "L" ? "Local" : "Multilateral" : recoilAfipData.IBCondicion === "L" ? "Local" : "Multilateral"
    let valueActividad = sessionStorage.getItem("afipData") ? JSON.parse(sessionStorage.getItem("afipData"))?.actividadAFIPCod === null ? "Sin actividad AFIP principal" : `${JSON.parse(sessionStorage.getItem("afipData"))?.actividadAFIPCod} - ${JSON.parse(sessionStorage.getItem("afipData"))?.actividadAFIPNombre}` : recoilAfipData.actividadAFIPCod === null ? "Sin actividad AFIP principal" : `${recoilAfipData.actividadAFIPCod} - ${recoilAfipData.actividadAFIPNombre}`
    let IBCondicion = sessionStorage.getItem("afipData") ? JSON.parse(sessionStorage.getItem("afipData"))?.IBCondicion : recoilAfipData.IBCondicion

    const formik = useFormik({
        initialValues:{ 
            regimenIngresosBrutos: valueIB,
            actividadPrincipal: valueActividad,
            provinciaIngresosBrutos: IBCondicion === "M" ? null : 21,
            numeroIngresosBrutos: IBCondicion === "M" ? sessionStorage.getItem("cuit") : ""
        },
        onSubmit: (values)=>{ 
             sessionStorage.setItem("infoFiscal1", JSON.stringify({merchantIBProvinciaId: values.provinciaIngresosBrutos,
                merchantIBNumero: values.numeroIngresosBrutos,
                merchantIBCode: JSON.parse(sessionStorage.getItem("afipData"))?.IBCondicion || recoilAfipData.IBCondicion}))
             setComercio({
                 ...comercio,
                 merchantIBProvinciaId: values.provinciaIngresosBrutos === null ? null : Number(values.provinciaIngresosBrutos),
                 merchantIBNumero: values.numeroIngresosBrutos,
                 merchantIBCode: JSON.parse(sessionStorage.getItem("afipData"))?.IBCondicion || recoilAfipData.IBCondicion

             })
            setStepInfoFiscal(2)
        },
        validationSchema:validationSchema,
    });
    const formik2 = useFormik({
        initialValues:{
            iva: JSON.parse(sessionStorage.getItem("infoFiscal2"))?.merchantRetieneIVA === "Y" ? "true" : JSON.parse(sessionStorage.getItem("infoFiscal2"))?.merchantRetieneIVA === "N" && "false" || "",
            ganancias: JSON.parse(sessionStorage.getItem("infoFiscal2"))?.merchantRetieneIG === "Y" ? "true" : JSON.parse(sessionStorage.getItem("infoFiscal2"))?.merchantRetieneIG === "N" && "false" || "",
            ingresosBrutos: JSON.parse(sessionStorage.getItem("infoFiscal2"))?.merchantRetieneIB === "Y" ? "true" : JSON.parse(sessionStorage.getItem("infoFiscal2"))?.merchantRetieneIB === "N" && "false" || "",
            alicuota: JSON.parse(sessionStorage.getItem("infoFiscal2"))?.merchantRetieneIBAlicuota || null,
            combustible: JSON.parse(sessionStorage.getItem("infoFiscal2"))?.merchantCombustible === "Y" ? "true" : JSON.parse(sessionStorage.getItem("infoFiscal2"))?.merchantCombustible === "N" && "false" || "",
            microempresas: JSON.parse(sessionStorage.getItem("infoFiscal2"))?.merchantMicroempresa === "Y" ? "true" : JSON.parse(sessionStorage.getItem("infoFiscal2"))?.merchantMicroempresa === "N" && "false" ||  ""
        },
        onSubmit: (values)=>{ 
            sessionStorage.setItem("infoFiscal2", JSON.stringify({
                merchantRetieneIVA: values.iva === "true" ? "Y" : "N",
                merchantRetieneIG: values.ganancias === "true" ? "Y" : "N",
                merchantRetieneIB: values.ingresosBrutos === "true" ? "Y" : "N",
                merchantRetieneIBAlicuota: values.alicuota,
                merchantCombustible: values.combustible === "true" ? "Y" : "N",
                merchantMicroempresa: values.microempresas === "true" ? "Y" : "N"
            }))
            setComercio({
                ...comercio,
                merchantRetieneIVA: values.iva === "true" ? "Y" : "N",
                merchantRetieneIG: values.ganancias === "true" ? "Y" : "N",
                merchantRetieneIB: values.ingresosBrutos === "true" ? "Y" : "N",
                merchantRetieneIBAlicuota: values.alicuota,
                merchantCombustible: values.combustible === "true" ? "Y" : "N",
                merchantMicroempresa: values.microempresas === "true" ? "Y" : "N"
            })
            setStepInfoFiscal(3)
        },
        validationSchema:validationSchema2,
        initialErrors:{
            merchantRetieneIVA: false,
            merchantRetieneIG: false,
            merchantRetieneIB: false,
            merchantRetieneIBAlicuota: false,
            merchantCombustible: false,
            merchantMicroempresa: false,
          }
    });


    const formik3 = useFormik({
        initialValues: { 
            sujetoObligado: JSON.parse(sessionStorage.getItem("afipData"))?.personaFJ === "F" ? usuarioAdicional?.usuarioSujetoObligado === "Y" ? true : usuarioAdicional?.usuarioSujetoObligado === "N" ? false : "" : "",
            politicamenteExpuesto: JSON.parse(sessionStorage.getItem("afipData"))?.personaFJ === "F" ? usuarioAdicional?.usuarioPoliticamenteExpuesto === "Y" ? true : usuarioAdicional?.usuarioPoliticamenteExpuesto === "N" ? false : "" : "",
            inversorNoResidenteFiscal: JSON.parse(sessionStorage.getItem("infoFiscal3"))?.inversorNoResidenteFiscal === "Y" ? "true" : JSON.parse(sessionStorage.getItem("infoFiscal3"))?.inversorNoResidenteFiscal === "N" && "false" || "",
            paisResidencia: JSON.parse(sessionStorage.getItem("infoFiscal3"))?.paisId || "",
            tinNif: JSON.parse(sessionStorage.getItem("infoFiscal3"))?.merchantNoResidenteTC || null,
            numeroTinNif: JSON.parse(sessionStorage.getItem("infoFiscal3"))?.merchantNoResidenteCuenta || ""
        },
        onSubmit: (values)=> {
            sessionStorage.setItem("infoFiscal3", JSON.stringify({
                merchantPEP: values.politicamenteExpuesto === "true" ? "Y": "N",
                merchantSO: values.sujetoObligado === "true" ? "Y" : "N",
                merchantNoResidente: values.inversorNoResidenteFiscal === "true" ? "Y" : "N",
                paisId: values.paisResidencia,
                merchantNoResidenteTC: values.tinNif,
                merchantNoResidenteCuenta: values.numeroTinNif
            }))
            setComercio({
                ...comercio,
                merchantPEP: values.politicamenteExpuesto === "true" ? "Y": "N",
                merchantSO: values.sujetoObligado === "true" ? "Y" : "N",
                merchantNoResidente: values.inversorNoResidenteFiscal === "true" ? "Y" : "N",
                paisId: values.paisResidencia,
                merchantNoResidenteTC: values.tinNif,
                merchantNoResidenteCuenta: values.numeroTinNif
            })
            nextStep(3)
        },
        validationSchema:validationSchema3,
        initialErrors:{
            merchantPEP: false,
            merchantSO: false,
            merchantNoResidente: false,
            paisId: false,
            merchantNoResidenteTC: false,
            merchantNoResidenteCuenta: false,
          }
    })


    switch(stepInfoFiscal){ 
        case 1: { 
            return (
                <>
                <FormInformacionFiscal formik={formik}/>
                <Box> 
                    <ButtonGradient size={"medium"} text={"Continuar"} action={formik.handleSubmit} disabled={!formik.isValid}/>
                </Box>
                <Box marginTop={"15px"} marginBottom={"15px"}> 
                    <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>nextStep(1)}/>
              </Box>
            </>
            )
        }
        case 2: { 
                return (
                    <> 
         
                    <FormInformacionFiscal2 formik={formik2}/>
                        <Box> 
                            <ButtonGradient size={"medium"} text={"Continuar"} action={formik2.handleSubmit} disabled={!formik2.isValid}/>
                        </Box>
                      <Box marginTop={"15px"} marginBottom={"15px"}> 
                            <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>setStepInfoFiscal(1)}/>
                      </Box>
                    </>
                )
        }
        case 3: {
            return (
                <> 
                    <FormInformacionFiscal3 formik={formik3} paises={paises} usuarioAdicional={usuarioAdicional} />
                    <Box> 
                        <ButtonGradient size={"medium"} text={"Continuar"} action={formik3.handleSubmit} disabled={!formik3.isValid}/>
                    </Box>
                    <Box marginTop={"15px"} marginBottom={"15px"}> 
                        <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>setStepInfoFiscal(2)}/>
                    </Box>
                </>
            )
        }
        default:{ 

        }
    }


}

export {
    ContainerInformacionFiscal
}
