
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(()=> ({
    containerInput: {
       
            '& .MuiFormControl-root': {
                '& label':{
                    color: "#006E54",
                    fontSize: "18px",
                },
             
                '& input:focus': {
                    '&, &::after' :{
                        borderBottom:"2px solid #006E54 !important", 
                        zIndex: "2"
                    },
                }
         }

    },
    containerActividadFiscal: { 
        marginTop: "12px",
            '& label':{
                position:"sticky",
                color: "#006E54",
                fontSize: "14px",
                marginBottom: "16px",
                marginLeft:"4px"
            },
            '& input': { 
               textAlign:"start",
                height: "160px",
                wordBreak: "break-word",
            },
            '& textarea': {
                marginTop: "4px",
                height: "80%",
                width: "100%",
                borderRadius: "10px",
                resize:"none",
                fontSize: "16px",
                padding: "8px"
            },
            
        
     
    }
}))