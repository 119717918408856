import React from 'react'
import styles from "./StylesGeneral.module.scss"
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import XGridServer from '../../components/Grid/GridServer/GridServer';
import { userCredentials } from '../../recoilState/GlobalState';
import { useRecoilState } from 'recoil';
import {MerchantSucursalGet} from "../../services/hooli-services/Merchants/MerchantSucursalGet"
import HeaderButtonTable from '../../components/Button/HeaderButton';
import ModalContainer from './ModalContainer';
import { ProvinciaGet } from '../../services/hooli-services/Provincias/ProvinciasGet';

export default function Sucursales() {
    const [selectRow, setSelectRow] = useState(null)
    const [sucursalesList, setSucursalesList] = useState([])
    const [provincias, setProvincias] = useState(false);
    const [page, setPage] = useState(1)
    const [rowsCount, setRowsCount] = useState(0)
    const [pageSize, setPageSize] = useState(0);
    const [openModal, setOpenModal] = useState(false)
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [ubication, setUbicacion] = useState(null)
    const columns = [
        { field: 'sucursalNombre', headerName: 'Nombre sucursal', flex: 1, minWidth: 100 },
        { field: 'sucursalDireccion', headerName: 'Dirección', flex: 1, minWidth: 100, align: "left", headerAlign: 'left' },
    ];
    const getSucursales = async (cred) => {
        const data = await MerchantSucursalGet(cred, cred.merchantId)
        // console.log("data", data)
        if(data.status.code === 1){
            let formatList = data.result.map((data, i) => ({
                id: data.merchantSucursalId,
                sucursalId: data.merchantSucursalId,
                sucursalNombre: data.merchantSucursalNombre,
                sucursalDireccion: `${data.domicilioCalle} ${data.domicilioCasa}`,
                domicilioCPA: data.domicilioCPA,
                domicilioCalle: data.domicilioCalle,
                domicilioCasa: data.domicilioCasa,
                domicilioLatitud: data.domicilioLatitud,
                domicilioLongitud: data.domicilioLongitud,
                domicilioPiso: data.domicilioPiso,
                domicilioUnidad: data.domicilioUnidad,
                localidadId: data.localidadId,
                localidadNombre: data.localidadNombre,
                merchantId: data.merchantId,
                merchantNombre: data.merchantNombre,
                merchantPlanDescuento: data.merchantPlanDescuento,
                merchantSucursalEsCasaCentral: data.merchantSucursalEsCasaCentral,
                merchantSucursalVersion: data.merchantSucursalVersion,
                merchantSucursalId: data.merchantSucursalId,
                merchantSucursalNombre: data.merchantSucursalNombre,
                merchantSucursalTelefono: data.merchantSucursalTelefono,
                provinciaNombre: data.provinciaNombre,
            }))
            setSucursalesList(formatList)
        }
    }
    const getProvinicias = async ()=>{
        try{
          const response = await ProvinciaGet(credentials);
          if(response.status.code === 1 && response.result.length > 0) {
            setProvincias(response.result);
          }
          
        }catch(e){
          console.log("Error provinicas get",e)
        }
      }
      
    useEffect(() => {
        if (credentials.merchantId){
            getSucursales(credentials)
            getProvinicias()
        }
    }, [credentials])
    useEffect(() => {
        if (openModal === false && credentials.merchantId){
            getSucursales(credentials)
        }
    }, [openModal])
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((data) => {
            setUbicacion({ lat: data.coords.latitude, lng: data.coords.longitude })
        }, (error) => console.log(error))
    }, [])
  return (
    <>
        {openModal && <ModalContainer setSelectRow={setSelectRow} selectRow={selectRow} provincias={provincias} setModal={setOpenModal} modal={openModal} ubication={ubication}/>}
        <div className={styles.container}>
            <div className={styles.containerFilters}>
                <div className={styles.containerButtons}>
                    <HeaderButtonTable
                        disabled={selectRow?.status ? true : false}
                        text={"Agregar sucursal"}
                        link={`autenticaciones`}
                        setModal={setOpenModal}
                        data={{
                            action: "add",
                            title: "add",
                            button: "add",
                        }}
                    >
                        <AddRoundedIcon/>
                    </HeaderButtonTable>
                    <HeaderButtonTable
                        disabled={selectRow?.status ? false : true}
                        text={"Editar sucursal"}
                        link={`autenticaciones`}
                        setModal={setOpenModal}
                        data={{
                            action: "edit",
                            title: "edit",
                            button: "edit",
                        }}
                    >
                        <EditRoundedIcon/>
                    </HeaderButtonTable>
                    <HeaderButtonTable
                        disabled={selectRow?.status ? false : true}
                        text={"Eliminar sucursal"}
                        link={`autenticaciones`}
                        setModal={setOpenModal}
                        data={{
                            action: "del",
                            title: "del",
                            button: "del",
                        }}
                    >
                        <DeleteOutlineRoundedIcon/>
                    </HeaderButtonTable>
                </div>
            </div>
            <XGridServer columns={columns} setNumberPage={setPage} rowsCount={rowsCount} setPageSize={setPageSize} list={sucursalesList} rowSelect={selectRow} setRow={setSelectRow} pageSize={pageSize} onPageSizeChange={setPageSize}/>
        </div>
    </>
    )
}
