import React from 'react'
import styles from "./HeaderHooli.module.scss"
import logo from "../../assets/icons/rioNegroLogo.svg"

export default function HeaderHooli() {
  return (
    <section className={styles.container}>
        <img src={logo} alt='Logo Rio Negro' className={styles.logo}/>
    </section>
  )
}
