import React from 'react'
import { ContainerConstanciaAFIP } from '../constanciaAFIP';
import {ContainerConstanciaIngresosBrutos} from "../constanciaIngresosBrutos";
import {ContainerContratoSocial} from "../contratoSocial";
import { DesignacionAutoridadesVigente } from '../designacionAutoridadesVigentes';
import { Accionistas } from '../accionistas';
import { UltimoBalance } from '../ultimoBalance';
import { AprobacionBalance } from '../aprobacionBalance';
import { ConstanciaSO } from '../constanciaSO';
import { comercioData } from '../../../recoilState/GlobalState';
import { useRecoilState } from 'recoil';
import { OtrosDocumentos } from '../otrosDocumentos';
import { Apoderados } from '../apoderados';
import Beneficiarios from '../beneficiarios';

function ContainerDocumentos({nextStep, setStepDocumentos, stepDocumentos, setIsOpen}) {
    const [comercio,setDataComercios] = useRecoilState(comercioData);

if(stepDocumentos.step === 1){ 
   return (<ContainerConstanciaAFIP nextStep={nextStep} setStepDocumentos={setStepDocumentos} />)
} else if (stepDocumentos.step === 2) {
    return (
        <ContainerConstanciaIngresosBrutos nextStep={nextStep} setStepDocumentos={setStepDocumentos} data={comercio} />
    )
} else if(stepDocumentos.step === 3) { 
    return (
        <ConstanciaSO nextStep={nextStep} setStepDocumentos={setStepDocumentos} />
    )
}   else if(stepDocumentos.step === 4) { 
    return (
        <OtrosDocumentos nextStep={nextStep} setStepDocumentos={setStepDocumentos} />
    )
}else if(stepDocumentos.step === 5) { 
    return (
        <ContainerContratoSocial nextStep={nextStep} setStepDocumentos={setStepDocumentos} />
    )
}else if(stepDocumentos.step === 6) { 
    return (
           <DesignacionAutoridadesVigente nextStep={nextStep} setStepDocumentos={setStepDocumentos} />
        )
    }else if(stepDocumentos.step === 7) { 
        return (
            <Accionistas nextStep={nextStep} setStepDocumentos={setStepDocumentos} />
        )
    }else if(stepDocumentos.step === 8) { 
        return (
           <UltimoBalance nextStep={nextStep} setStepDocumentos={setStepDocumentos} />
        )
    }else if(stepDocumentos.step === 9) { 
        return (
        <AprobacionBalance nextStep={nextStep} setStepDocumentos={setStepDocumentos} />
    )
}else if(stepDocumentos.step === 10) { 
    return (
        <Apoderados nextStep={nextStep} setStepDocumentos={setStepDocumentos} />
    )
}else if(stepDocumentos.step === 11) { 
    return (
        <Beneficiarios nextStep={nextStep} setStepDocumentos={setStepDocumentos} />
    )
}
  
}

export {
    ContainerDocumentos
} 
