import { AxiosPostBody } from '../../../types/AxiosPost';
import { UserCredentialsType } from '../../../types/types';
import axiosPost from '../axiosPost';

export const UrlDocumentosGet = async (arg: UserCredentialsType, fileName: string, code: string, cuit: string, doc: string) => {
    const body: AxiosPostBody = {
        "service": "URLFirmadaTemporal",
        "params": {
            fileName: `${fileName}`,
            code: `${code}`,
            cuit: `${cuit}`,
            beneficiarioNroDeDocumento: `${doc ? doc : null}`
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
       
        return result
    } catch (err) {
        console.log(err)
    }
};