import React from "react";
// Style
import Alert from "@mui/lab/Alert";
import { Snackbar } from "@mui/material";
import { SnackbarStyles } from "./Snackbar.styles";
// Recoil
import { useRecoilValue } from 'recoil';
import { snackbarData } from '../../../recoilState/GlobalState'

export default function SnackBar(){
    const { message, severity, open } = useRecoilValue(snackbarData)
    const classes = SnackbarStyles();

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            key="top-center"
            className={classes.root}
        >
            <Alert
                className={classes.alert}
                elevation={6}
                severity={severity}
                color={severity}
            >
                <span className={classes.message}>{message}</span>
            </Alert>
        </Snackbar>
    );
};
