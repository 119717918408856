import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(()=>({
    transparent:{
        // opacity: "40%",
        '& img':{
            filter: "brightness(0) saturate(100%) invert(36%) sepia(13%) saturate(3084%) hue-rotate(119deg) brightness(86%) contrast(101%);"
        }
    },
    imgComerciosDefault:{
        width: "45px",
        height: "45px",
        filter: "brightness(0) saturate(100%) invert(36%) sepia(13%) saturate(3084%) hue-rotate(119deg) brightness(86%) contrast(101%);"
    }, 
    imgAngleBrackets: {
        width: "16px",
        height: "16px",
        
    },
    title: { 
        color: "#006E54",
        fontSize: "18px !important",
    },
    subtitle: { 
        fontSize:"16px !important",
        color:"#2b2b2a"
    }
}));