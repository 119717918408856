import { AxiosPostBody } from '../../../types/AxiosPost';
import { UserCredentialsType } from '../../../types/types';
import axiosPost from '../axiosPost';

export const UrlDocumentosDel = async (arg: UserCredentialsType, cuit: string, doc: string) => {
    const body: AxiosPostBody = {
        "service": "RemoveBeneficiarioTempFiles",
        "params": {
            cuit: `${cuit}`,
            beneficiarioNroDeDocumento: `${doc ? doc : null}`
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
       
        return result
    } catch (err) {
        console.log(err)
    }
};