import axiosPost from '../axiosPost';
import { MerchantUsuarioType, SucursalEdit, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantSucursalUpd = async (arg: UserCredentialsType, data: SucursalEdit) => {
    const body: AxiosPostBody = {
        "service": "MerchantSucursalUpd",
        "params": {
            merchantSucursalId: data.merchantSucursalId,
            merchantSucursalNombre: data.merchantSucursalNombre,
            domicilioCalle: data.domicilioCalle,
            domicilioCasa: data.domicilioCasa,
            domicilioPiso: data.domicilioPiso,
            domicilioUnidad: data.domicilioUnidad,
            domicilioCPA: data.domicilioCPA,
            localidadId: Number(data.localidadId),
            merchantSucursalTelefono: data.merchantSucursalTelefono,
            domicilioLatitud: Number(data.domicilioLatitud),
            domicilioLongitud: Number(data.domicilioLongitud),
            merchantSucursalVersion: data.merchantSucursalVersion,
            registroXUsuarioLatitud: data.registroXUsuarioLatitud,
            registroXUsuarioLongitud: data.registroXUsuarioLongitud,
        }
    }

    try {
        const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        return result
        
    } catch (err) {
        console.log(err)
    }
}