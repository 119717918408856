import React from 'react';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { Identificacion } from '../../../components/alta-comercios/forms/identificacion';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useRecoilState, useRecoilValue } from 'recoil';
import { afipData, comercioData } from '../../../recoilState/GlobalState';
import { useNavigate } from 'react-router-dom';

export function ContainerIdentificacion({nextStep}) {
  const navigate = useNavigate()
  const recoilAfipData = useRecoilValue(afipData);
  const [comercio, setComercioData] = useRecoilState(comercioData);
  const validateSociety = (value) => {
    if(recoilAfipData.personaFJ === "J"){
      
    } else {

    }
  }
  const validationSchema = yup.object({
    nombre: yup.string().required("Debes ingresar un nombre"),
    tipoDeSociedad: recoilAfipData.personaFJ === "J" ? yup.number().required("Debes seleccionar un tipo de sociedad") : "",
    rubro: yup.number().required("Debes seleccionar un rubro"),
    cargo: yup.number().required("Debes ingresar un cargo")
});
  console.log(JSON.parse(sessionStorage.getItem("afipData")))
  let valueRz = sessionStorage.getItem("afipData") ? JSON.parse(sessionStorage.getItem("afipData")).personaFJ === "J" ? JSON.parse(sessionStorage.getItem("afipData")).merchantRZ : `${JSON.parse(sessionStorage.getItem("afipData")).usuarioApellidos}, ${JSON.parse(sessionStorage.getItem("afipData")).usuarioNombres}` : recoilAfipData.personaFJ === "J" ? recoilAfipData.merchantRZ : `${recoilAfipData.usuarioApellidos}, ${recoilAfipData.usuarioNombres}` ;
  let valuePersona = sessionStorage.getItem("afipData") ? JSON.parse(sessionStorage.getItem("afipData")).personaFJ === "F" ? "Física" : "Jurídica" : recoilAfipData.personaFJ === "F" ? "Física" : "Jurídica" ;
  const formik = useFormik({
    initialValues: {
        razonSocial: valueRz,
        tipoDePersona:valuePersona,
        cuit:sessionStorage.getItem("cuit") || recoilAfipData.cuit,
        nombre: JSON.parse(sessionStorage.getItem("identificacionInfo"))?.merchantNombre || "",
        tipoDeSociedad:  JSON.parse(sessionStorage.getItem("identificacionInfo"))?.tipoSocietarioId || "",
        rubro:  JSON.parse(sessionStorage.getItem("identificacionInfo"))?.rubroDeMerchantId || "",
        cargo:  JSON.parse(sessionStorage.getItem("identificacionInfo"))?.cargoId || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values)=> { 
      sessionStorage.setItem("identificacionInfo", JSON.stringify({merchantNombre: values.nombre,
        rubroDeMerchantId: values.rubro,
        cargoId: values.cargo,
        tipoSocietarioId: values.tipoDeSociedad,}));
      setComercioData({ ...comercio,
        merchantNombre: values.nombre,
        rubroDeMerchantId: values.rubro,
        cargoId: values.cargo,
        tipoSocietarioId: values.tipoDeSociedad === "" ? null : values.tipoDeSociedad,
      })
       nextStep(1)
    },
    initialErrors:{
      nombre: false
    }
}); 
const back = () => {
  navigate("/ingreso-cuit")
}
  return (
    <> 
          <Identificacion formik={formik}/>
          <Box> 
            <ButtonGradient size={"medium"} text={"Continuar"} action={formik.handleSubmit} disabled={!formik.isValid}/>
          </Box>
          <Box marginTop={"15px"} marginBottom={"15px"}> 
          <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={back}/>
        </Box>
    </>
  )
}
