import React, { useEffect, useState } from 'react';
import { DropZone } from '../../../components/alta-comercios/dropzone';
import { Box } from '@mui/material';
import { UrlDocumentosGet } from '../../../services/hooli-services/Documentos/UrlDocumentosGet';
import { DocumentosPut } from '../../../services/hooli-services/Documentos/DocumentosPut';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { useRecoilState, useRecoilValue } from 'recoil';
import { afipData, userCredentials } from '../../../recoilState/GlobalState';


export function ContainerConstanciaAFIP({nextStep, setStepDocumentos}) {
    const [file,setFile] = useState(null)
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const recoilAfipData = useRecoilValue(afipData);
    
    const urlDoc = async() => {
      // console.log(recoilAfipData.cuit.replaceAll('-', ''))
      const url = await UrlDocumentosGet(credentials, `${recoilAfipData.cuit.replaceAll('-', '')}_AFIP.pdf`, "AFIP" ,recoilAfipData.cuit );
      // console.log(url)
      await sendFile(url.result[0].url)
    }
    
    const sendFile = async (url) => {
      // console.log(file[0]) 
      await DocumentosPut(url, file[0])
    }

    const handleSubmit = ()=> {
      urlDoc();
      setStepDocumentos({
        step:2, 
        title: "Constancia de Inscripción de Ingresos Brutos",
        subtitle: "Podés descargar tu constancia de inscripción desde el sitio web de la AFIP"
      })
    }
  return (
   <> 
    <DropZone file={file} setFile={setFile} horizontal={false}/>
    <Box> 
            <ButtonGradient size={"medium"} text={"Continuar"} action={handleSubmit} disabled={!file}/>
          </Box>
          <Box marginTop={"15px"} marginBottom={"15px"}> 
          <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>nextStep(2)}/>
        </Box>
   </>
        
    
  )
}

 
