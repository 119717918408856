import React, { useEffect, useState } from 'react';
import { FiltrosStyles } from '../Filtros.styles';
import { formatNumber } from '../../services/Local-services';
import moment from 'moment';
// Skeleton
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// assets
import lupa from "../../assets/icons/lupa.png";
import reload from "../../assets/icons/actualizar.svg";
// Form
import { useFormik } from 'formik';
import * as yup from 'yup';
// Components
import HeaderButton from "../../components/utils/button/HeaderButton";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import SubmitButton from "../../components/utils/button/SubmitButton";
import { FormikDateInput, FormikSelectInput } from '../../components/utils/inputs/FormikInputs';
// recoil
import { useRecoilValue } from 'recoil';
import { rowSelected, userCredentials } from '../../recoilState/GlobalState';
import { MWSaldoActualGet } from '../../services/hooli-services';

const validationSchema = yup.object({
    hasta: yup.date().max(new Date().toISOString().substring(0, 10), 'Fecha maxima hoy'),
    desde: yup.date().max(yup.ref('hasta'), 'No puede ser superior a la segunda fecha')
});

export default function Filtros(props) {
    const [saldoActual, setSaldoActual] = useState({});
    const [saldoCargando, setSaldoCargando] = useState(true);

    const { status } = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const { filtros, setFiltros, cargando, updateList } = props;

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const retrieveSaldoActual = async () => {
        setSaldoCargando(true);
        const payload = {
            ...credentials,
            "merchantId": credentials.merchantId,
            "monedaCod": formik.values.moneda
        };
        const result = await MWSaldoActualGet(payload);
        setSaldoActual(result);
        setSaldoCargando(false);
    };

    useEffect(() => {
        retrieveSaldoActual();
    }, [formik.values.moneda, cargando])


    const classes = FiltrosStyles();
    const data = { moneda: { label: "Moneda" }, desde: { label: 'Desde' }, hasta: { label: 'Hasta' } };
    const monedas = [{ monedaCod: 'ARS', monedaNombre: 'Peso Argentino' }, { monedaCod: 'HC', monedaNombre: 'Hooli Coins' }];

    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className="saldo-actual">
                <p><b>Saldo actual:&nbsp;</b> <span>{saldoCargando || cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + formatNumber(saldoActual.saldoTotal)}</span></p>
                <p><b>Último movimiento:&nbsp;</b> <span>{saldoCargando || cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : moment(saldoActual.saldoUltimaFecha).format('DD/MM/YYYY')}</span></p>
            </div>
            <div className={classes.filtros}>
                <FormikSelectInput
                    data={data}
                    name="moneda"
                    formik={formik}
                    disabled={cargando}
                    options={monedas}
                    optionKey="monedaCod"
                    optionName="monedaNombre"
                    optionValue="monedaCod"
                    className={classes.input}
                />
                <FormikDateInput
                    data={data}
                    name="desde"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <FormikDateInput
                    data={data}
                    name="hasta"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <div className={classes.marginNormal}>
                    <SubmitButton texto="Buscar" />
                </div>
            </div>
            <Buttons>
                {status ?
                    <HeaderButton
                        text="Movimientos"
                        icon={lupa}
                        data={{ action: '' }}
                    />
                    :
                    <HeaderButton
                        text={"Actualizar pagina"}
                        icon={reload}
                        updateList={updateList}
                    />
                }
            </Buttons>
        </form>
    )
};