import { Box, Grid, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../components/utils/inputs/FormikInputs.js'
import { useStyles } from './AddSucursalStyles.js'
import Map from '../../components/map/index.jsx'
import { useRecoilValue } from 'recoil'
import { afipData } from '../../recoilState/GlobalState.js'
import axios from 'axios'

export default function AddEmpleados2({formik, sucursales, roles, credentials}) {
    const [step, setStep] = useState(1)
    const date = new Date().toISOString().slice(0, 10);
    const styles = useStyles();
    const dataFormik = {
        usuarioNroDeDocumento: {label: "Nro de doc", placeholder:"22222222"},
        merchantUsuarioRolId: {label: "Rol", placeholder:"Nombre"},
        merchantUsuarioSucursalId: {label: "Sucursal", placeholder:"7"},
    }
    useEffect(() => {
      formik.setFieldValue('merchantUsuarioFecha', date)
      formik.setFieldValue('merchantId', credentials.merchantId)
    }, [])
  
    return (
    <>
    <Grid container width={"100%"} maxWidth={"800px"} padding={"20px 0 50px 0"}>
        <Grid item width={"100%"} display={"flex"} gap={4}>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"usuarioNroDeDocumento"} formik={formik} variant="standard" disabled={true}/> 
        </Box>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}>
        <FormikSelectInput data={dataFormik} name={"merchantUsuarioRolId"} formik={formik} options={roles} optionKey={"rolId"} optionValue={"rolId"} optionName={"rolNombre"} />
        </Box>
        </Grid>
        <Grid item width={"100%"} display={"flex"} gap={4}> 
            <Box display={"flex"} flexDirection={"column"} width={"100%"} className={styles.containerInput}> 
                <FormikSelectInput data={dataFormik} name={"merchantUsuarioSucursalId"} formik={formik} options={sucursales} optionKey={"merchantSucursalId"} optionValue={"merchantSucursalId"} optionName={"merchantSucursalNombre"} />
            </Box>
        </Grid>
    </Grid>
    </>
  )
}
