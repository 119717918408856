import axiosPost from '../axiosPost';
import { EmpleadoDel, MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const QR30CodigoQRGet = async (arg: UserCredentialsType, merchantCajaId: EmpleadoDel) => {
    const body: AxiosPostBody = {
        "service": "QR30CodigoQRGet",
        "params": {
            merchantCajaId: merchantCajaId
        }
    }

    try {
        const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        return result
        
    } catch (err) {
        console.log(err)
    }
}