import React, { useEffect,useRef,useState } from 'react';
import { Box } from '@mui/material';
import ButtonGradient from '../../components/utils/button/ButtonGradient';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useRecoilValue } from 'recoil';
import { afipData, userCredentials } from '../../recoilState/GlobalState';
import AddSucursal from './AddSucursal';
import styles from "./ModalContainer.module.scss";
import {MerchantSucursalIns} from "../../services/hooli-services/Merchants/MerchantSucursalIns";
import {MerchantSucursalUpd} from "../../services/hooli-services/Merchants/MerchantSucursalUpd";
import {MerchantSucursalDel} from "../../services/hooli-services/Merchants/MerchantSucursalDel";
import {MerchantSucursalCajaIns} from "../../services/hooli-services/Merchants/MerchantSucursalCajaIns";
import {MerchantSucursalCajaUpd} from "../../services/hooli-services/Merchants/MerchantSucursalCajaUpd";
import {MerchantSucursalCajaDel} from "../../services/hooli-services/Merchants/MerchantSucursalCajaDel";
import {MerchantEmpleadoIns} from "../../services/hooli-services/Merchants/MerchantEmpleadosIns";
import {MerchantEmpleadoUpd} from "../../services/hooli-services/Merchants/MerchantEmpleadosUpd";
import {MerchantEmpleadoDel} from "../../services/hooli-services/Merchants/MerchantEmpleadosDel";
import EditSucursal from './EditSucursal';
import EditCajas from './EditCajas';
import AddCajas from './AddCajas';
import AddEmpleados from './AddEmpleados';
import EditEmpleados from './EditEmpleados';
import AddEmpleados2 from './AddEmpleados2';
import { usuarioBasicosGet } from '../../services/hooli-services';
import QrCajas from './QrCajas';
import {QR30CodigoQRGet} from '../../services/hooli-services/Merchants/QrGet'

export default function ModalContainer({provincias = [], sucursales = [], setModal, setSelectRow, selectRow, modal, ubication, roles = []}) {
    const date = new Date().toISOString();
    const [step, setStep] = useState(1);
    const genero = [{name: "Masculino", value: "M"}, {name: "Femenino", value: "F"}, {name: "Otro", value: "X"}];
    const [coords, setCoords] = useState({ 
        lat: -38.416097,
        lng: -63.616672
      });
    const credentials = useRecoilValue(userCredentials);
    const [qr, setQr] = useState()
    const getQr = async () => {
        const qrCode = await QR30CodigoQRGet(credentials, selectRow.row.merchantCajaId)
        setQr(qrCode.result[0].QR30CajaCodigoQR)
    }
    const qrRef = useRef();
    const printQr = () => {
        const svg = qrRef.current.querySelector('svg');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngUrl = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
        let downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'qrcode.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    };

    const validationSchemaAdd = yup.object({
        merchantSucursalNombre: yup.string().required("Debes ingresar un nombre"),
        domicilioCalle: yup.string().required("Debes ingresar tu calle"),
        domicilioCasa: yup.string().required("Debes ingresar tu casa"),
        domicilioCPA: yup.string().required("Debes ingresar tu CPA"),
        merchantSucursalTelefono: yup.string().required("Debes ingresar tu numbero"),
    })
    const validationSchemaAddCaja = yup.object({
        merchantSucursalId: yup.string().required("Debes seleccionar una sucursal"),
        merchantCajaNombre: yup.string().required("Debes ingresar un nombre de caja"),
        merchantCajaNumero: yup.number().required("Debes ingresar un numero de caja"),
    })
    const validationSchemaAddEmpleado = yup.object({
        usuarioNroDeDocumento: yup.string().required("Debes ingresar su documento"),
        usuarioSexo: yup.string().required("Debe seleccionar un sexo"),
    })
    const validationSchemaAddEmpleado2 = yup.object({
        usuarioNroDeDocumento : yup.string().required("Debes ingresar su documento"),
        merchantUsuarioRolId: yup.string().required("Debes seleccionar un rol"),
        merchantUsuarioSucursalId : yup.string().required("Debes seleccionar una sucursal"),
    })
    
      const formikAdd = useFormik({
        initialValues: { 
            merchantId: credentials?.merchantId,
            merchantSucursalNombre: "",
            domicilioCalle: "",
            domicilioCasa: "",
            domicilioPiso: "",
            domicilioUnidad: "",
            domicilioCPA: "",
            localidadId: "",
            provinciaId: provincias[0]?.provinciaId,
            merchantSucursalTelefono: "",
            domicilioLatitud : -38.416097,
            domicilioLongitud : -63.616672,
            registroXUsuarioLatitud : ubication?.lat?.toFixed(6),
            registroXUsuarioLongitud : ubication?.lng?.toFixed(6),
        },
        validationSchema: validationSchemaAdd,
        onSubmit: async(values)=> { 
            // console.log(values, "somos los values");
            await MerchantSucursalIns(credentials, values)
            setModal(false)
        },
        initialErrors:{
            merchantId: false,
            merchantSucursalNombre: false,
            domicilioCalle: false,
            domicilioCasa: false,
            domicilioPiso: false,
            domicilioUnidad: false,
            domicilioCPA: false,
            localidadId : false,
            merchantSucursalTelefono: false,
            domicilioLatitud : false,
            domicilioLongitud : false,
            registroXUsuarioLatitud : false,
            registroXUsuarioLongitud : false,
        }
    }); 
    const formikEdit = useFormik({
        initialValues: { 
            merchantId: selectRow?.row?.merchantId,
            merchantSucursalNombre: selectRow?.row?.merchantSucursalNombre,
            merchantSucursalId: selectRow?.row?.merchantSucursalId,
            domicilioCalle: selectRow?.row?.domicilioCalle,
            domicilioCasa: selectRow?.row?.domicilioCasa,
            domicilioPiso: selectRow?.row?.domicilioPiso,
            domicilioUnidad: selectRow?.row?.domicilioUnidad,
            domicilioCPA: selectRow?.row?.domicilioCPA,
            localidadId: selectRow?.row?.localidadId,
            provinciaId: provincias[0]?.provinciaId,
            merchantSucursalTelefono: selectRow?.row?.merchantSucursalTelefono,
            domicilioLatitud : selectRow?.row?.domicilioLatitud,
            domicilioLongitud : selectRow?.row?.domicilioLongitud,
            merchantSucursalVersion: selectRow?.row?.merchantSucursalVersion,
            registroXUsuarioLatitud : ubication?.lat?.toFixed(6),
            registroXUsuarioLongitud : ubication?.lng?.toFixed(6),
        },
        onSubmit: async(values)=> { 
            // console.log(values, "somos los values");
            await MerchantSucursalUpd(credentials, values)
            setModal(false)
        },
        initialErrors:{
            merchantId: false,
            merchantSucursalNombre: false,
            domicilioCalle: false,
            domicilioCasa: false,
            domicilioPiso: false,
            domicilioUnidad: false,
            domicilioCPA: false,
            localidadId : false,
            merchantSucursalTelefono: false,
            domicilioLatitud : false,
            domicilioLongitud : false,
            registroXUsuarioLatitud : false,
            registroXUsuarioLongitud : false,
        }
    }); 
    const formikAddCaja = useFormik({
        initialValues: { 
            merchantSucursalId : sucursales[0]?.merchantSucursalId,
            merchantCajaNombre: "",
            merchantCajaNumero : "",
        },
        validationSchema: validationSchemaAddCaja,
        onSubmit: async(values)=> { 
            // console.log(values, "somos los values");
            await MerchantSucursalCajaIns(credentials, values)
            setModal(false)
        },
        initialErrors:{
            merchantSucursalId : false,
            merchantCajaNombre: false,
            merchantCajaNumero : false,
        }
    }); 
    const formikEditCaja = useFormik({
        initialValues: { 
            merchantCajaId : selectRow?.row?.merchantCajaId,
            merchantCajaNombre: selectRow?.row?.merchantCajaNombre,
            merchantCajaNumero: selectRow?.row?.merchantCajaNumero,
            merchantCajaVersion: selectRow?.row?.merchantCajaVersion,
        },
        onSubmit: async(values)=> { 
            // console.log(values, "somos los values");
            await MerchantSucursalCajaUpd(credentials, values)
            setModal(false)
        },
        initialErrors:{
            merchantCajaId: false,
            merchantCajaNombre: false,
            merchantCajaNumero: false,
            merchantCajaVersion: false,
        }
    }); 
    const formikAddEmpleados = useFormik({
        initialValues: { 
            usuarioNroDeDocumento : "",
            usuarioSexo: genero[0]?.value,
            merchantId : credentials?.merchantId,
            merchantUsuarioFecha: date?.slice(0, 10),
        },
        validationSchema: validationSchemaAddEmpleado,
        onSubmit: async(values)=> { 
            // console.log(values, "somos los values");
            const user = await usuarioBasicosGet({...credentials, dni: values.usuarioNroDeDocumento, sexo: values.usuarioSexo})
            // console.log(user, "user");
            formikAddEmpleados2.setValues({usuarioNroDeDocumento : user[0]?.usuarioNroDeDocumento, merchantUsuarioRolId: roles[0]?.rolId, merchantUsuarioSucursalId: sucursales[0]?.merchantSucursalId})
            setStep(2)
        },
        initialErrors:{
            usuarioNroDeDocumento: false,
            usuarioSexo: false   
        }
    }); 
    const formikAddEmpleados2 = useFormik({
        initialValues: { 
            usuarioNroDeDocumento : formikAddEmpleados.values.usuarioNroDeDocumento,
            merchantUsuarioRolId: roles[0]?.rolId,
            merchantId : credentials?.merchantId,
            merchantUsuarioSucursalId : sucursales[0]?.merchantSucursalId,
            merchantUsuarioFecha: date?.slice(0, 10),
        },
        validationSchema: validationSchemaAddEmpleado2,
        onSubmit: async(values2)=> { 
            // console.log(values2, "somos los values 2");
            await MerchantEmpleadoIns(credentials, values2)
            setModal(false)
        },
        initialErrors:{
            usuarioNroDeDocumento: false,
            merchantUsuarioRolId: false, 
            merchantId: false, 
            merchantUsuarioSucursalId: false, 
            merchantUsuarioFecha: false,         
        }
    }); 
    const formikEditEmpleados = useFormik({
        initialValues: { 
            merchantUsuarioUsuarioId : selectRow?.row?.usuarioEmpleadoId,
            merchantUsuarioRolId: roles[0]?.rolId,
            merchantId : credentials?.merchantId,
            merchantUsuarioSucursalId : selectRow?.row?.merchantSucursalId,
            merchantUsuarioFecha: "",
        },
        onSubmit: async(values)=> { 
            // console.log(values, "somos los values");
            await MerchantEmpleadoUpd(credentials, values)
            setModal(false)
        },
        initialErrors:{
            merchantUsuarioUsuarioId: false,
            merchantUsuarioRolId: false,
            merchantId: false,
            merchantUsuarioSucursalId: false,
            merchantUsuarioFecha: false,
        }
    }); 
    
    const handleDelete = async() => {
        await MerchantSucursalDel(credentials, selectRow.row.merchantSucursalId)
        setSelectRow({ status: false, row: {} })
        setModal(false)
    }
    const handleDeleteCaja = async() => {
        await MerchantSucursalCajaDel(credentials, selectRow.row.merchantCajaId)
        setSelectRow({ status: false, row: {} })
        setModal(false)
    }
    const handleDeleteEmpleados = async() => {
        await MerchantEmpleadoDel(credentials, {
            merchantUsuarioUsuarioId: selectRow.row.usuarioEmpleadoId,
            merchantId: selectRow.row.merchantId,
            merchantUsuarioFecha: selectRow.row.merchantUsuarioFecha})
        setSelectRow({ status: false, row: {} })
        setModal(false)
    }
    useEffect(() => {
        if(modal === "qrCajas"){
            getQr()
        }
    }, [modal])
    
  return (
    <>
    {modal === "add" && <div className={styles.container}> 
        <div className={styles.modal}>
            <AddSucursal formik={formikAdd} coords={coords} setCoords={setCoords} provincias={provincias}/>
            <Box> 
                <ButtonGradient size={"medium"} text={"Continuar"} action={formikAdd.handleSubmit} disabled={!formikAdd.isValid} />
            </Box>
            <Box marginTop={"15px"} marginBottom={"15px"}> 
            <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={() => setModal(false)} />
            </Box>
        </div>
    </div>}
    {modal === "edit" && <div className={styles.container}> 
        <div className={styles.modal}>
            <EditSucursal formik={formikEdit} coords={coords} setCoords={setCoords} provincias={provincias}/>
            <Box> 
                <ButtonGradient size={"medium"} text={"Continuar"} action={formikEdit.handleSubmit} />
            </Box>
            <Box marginTop={"15px"} marginBottom={"15px"}> 
            <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={() => setModal(false)} />
            </Box>
        </div>
    </div>}
    {modal === "del" && <div className={styles.container}> 
        <div className={styles.modal}>
            <h2 className={styles.titleDelete}>{`Estas por eliminar la sucursal ${selectRow.row.merchantSucursalNombre}`}</h2>
            <Box> 
                <ButtonGradient size={"medium"} text={"Eliminar"} action={handleDelete} />
            </Box>
            <Box marginTop={"15px"} marginBottom={"15px"}> 
            <ButtonGradient size={"medium"} text={"Cancelar"} color="transparent" action={() => setModal(false)} />
            </Box>
        </div>
    </div>}
    {modal === "addCajas" && <div className={styles.container}> 
        <div className={styles.modal}>
            <AddCajas formik={formikAddCaja} sucursales={sucursales} />
            <Box> 
                <ButtonGradient size={"medium"} text={"Continuar"} action={formikAddCaja.handleSubmit} disabled={!formikAddCaja.isValid} />
            </Box>
            <Box marginTop={"15px"} marginBottom={"15px"}> 
            <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={() => setModal(false)} />
            </Box>
        </div>
    </div>}
    {modal === "editCajas" && <div className={styles.container}> 
        <div className={styles.modal}>
            <EditCajas formik={formikEditCaja} />
            <Box> 
                <ButtonGradient size={"medium"} text={"Continuar"} action={formikEditCaja.handleSubmit} />
            </Box>
            <Box marginTop={"15px"} marginBottom={"15px"}> 
            <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={() => setModal(false)} />
            </Box>
        </div>
    </div>}
    {modal === "delCajas" && <div className={styles.container}> 
        <div className={styles.modal}>
            <h2 className={styles.titleDelete}>{`Estas por eliminar la sucursal ${selectRow.row.merchantCajaNombre}`}</h2>
            <Box> 
                <ButtonGradient size={"medium"} text={"Eliminar"} action={handleDeleteCaja} />
            </Box>
            <Box marginTop={"15px"} marginBottom={"15px"}> 
            <ButtonGradient size={"medium"} text={"Cancelar"} color="transparent" action={() => setModal(false)} />
            </Box>
        </div>
    </div>}
    {modal === "qrCajas" && <div className={styles.container}> 
        <div className={styles.modal} >
            <div id={"containerQr"} ref={qrRef}>
                <QrCajas row={selectRow} credentials={credentials} qr={qr} />
            </div>
            <Box> 
                <ButtonGradient size={"medium"} text={"Descargar"} action={() => printQr()} />
            </Box>
            <Box marginTop={"15px"} marginBottom={"15px"}> 
                <ButtonGradient size={"medium"} text={"Cancelar"} color="transparent" action={() => setModal(false)} />
            </Box>
        </div>
    </div>}
    {modal === "addEmpleados" && <div className={styles.container}> 
        <div className={styles.modal}>
            {step === 1 && <>
                <AddEmpleados formik={formikAddEmpleados} genero={genero} />
                <Box> 
                    <ButtonGradient size={"medium"} text={"Continuar"} action={formikAddEmpleados.handleSubmit} disabled={!formikAddEmpleados.isValid} />
                </Box>
                    <Box marginTop={"15px"} marginBottom={"15px"}> 
                    <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={() => setModal(false)} />
                </Box>
            </>}
            {step === 2 && <> 
            <AddEmpleados2 formik={formikAddEmpleados2} sucursales={sucursales} roles={roles} credentials={credentials} />
            <Box> 
                <ButtonGradient size={"medium"} text={"Continuar"} action={formikAddEmpleados2.handleSubmit} disabled={!formikAddEmpleados2.isValid} />
            </Box>
            <Box marginTop={"15px"} marginBottom={"15px"}> 
                <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={() => setModal(false)} />
            </Box>
            </>
            }
        </div>
    </div>}
    {modal === "editEmpleados" && <div className={styles.container}> 
        <div className={styles.modal}>
            <EditEmpleados formik={formikEditEmpleados} sucursales={sucursales} roles={roles} />
            <Box> 
                <ButtonGradient size={"medium"} text={"Continuar"} action={formikEditEmpleados.handleSubmit} />
            </Box>
            <Box marginTop={"15px"} marginBottom={"15px"}> 
            <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={() => setModal(false)} />
            </Box>
        </div>
    </div>}
    {modal === "delEmpleados" && <div className={styles.container}> 
        <div className={styles.modal}>
            <h2 className={styles.titleDelete}>{`Estas por eliminar al empleado ${selectRow.row.usuarioNombreCompleto}`}</h2>
            <Box> 
                <ButtonGradient size={"medium"} text={"Eliminar"} action={handleDeleteEmpleados} />
            </Box>
            <Box marginTop={"15px"} marginBottom={"15px"}> 
            <ButtonGradient size={"medium"} text={"Cancelar"} color="transparent" action={() => setModal(false)} />
            </Box>
        </div>
    </div>}
    </>
  )
}
