
import { makeStyles } from "@mui/styles";
import { color } from "chart.js/helpers";

export const useStyles = makeStyles(()=> ({
    checkbox: {
        color: "#006E54",
        fontWeight: "400",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column-reverse",
        justifyContent: "center",
        gap: "4px", 
        width: "100%",
        marginRight: "0px !important",
        '& .MuiFormControlLabel-label': {
            fontSize: "14px !important",
            fontWeight: "700",
            marginLeft: "2px"
        },
        '& span': {
            display: "flex",
            justifySelf: "start",
            padding: "0px",
            alignSelf: "self-start !important",
            '& svg': {
                filter: "brightness(0) saturate(100%) invert(26%) sepia(94%) saturate(737%) hue-rotate(127deg) brightness(97%) contrast(101%)"
            }
        }
    },
    btn: {
        color: "#999",
        fontSize: "18px",
        fontWeight: "400",
        cursor: "pointer"
    },
    select: {
        color: "#006E54",
        borderBottom: "2px solid #006E54",
        fontWeight: "700"
    },
    containerInput: {
        alignItems: "baseline",
        '& .MuiBox-root':{
            width: "100%"
        },
        '& >div': {
                cursor: "pointer",
                display: "flex",
                '& img': {
                    filter: "brightness(0) saturate(100%) invert(32%) sepia(10%) saturate(6275%) hue-rotate(129deg) brightness(92%) contrast(101%)",
                },
                '& section':{
                    display: "none",
                },
                '& :hover section': {
                    color: "#000",
                    display: "flex",
                    position: "absolute",
                    padding: "8px",
                    backgroundColor: "#eee",
                    zIndex: "2",
                    top: "0",
                    borderRadius: "10px",
                    minWidth: "200px",
                    right: "0",
                    '& a': {
                        color: "#006E54",
                        display: "inline",
                    }
                }
            },
        '& .MuiFormControl-root':{
            '& .MuiFormLabel-root': {
                color: "#006E54",
                fontSize: "18px",
            },
            '& .MuiFormGroup-root': {
                display: "flex",
                flexDirection: "row",
                marginLeft: "15px",
                '& .MuiFormControlLabel-root': {
                    flexDirection: "row-reverse",
                    color: "#707070",
                    '& .MuiButtonBase-root': {
                        color: "#5567AE"
                    }
                }
            }
        }
    },
    containerActividadFiscal: { 
        '& .MuiInputBase-root': {
            marginTop:"0px",    
            borderTop: "1px solid rgba(0, 0, 0, 0.42)",
            borderLeft: "1px solid rgba(0, 0, 0, 0.42)",
            borderRight:"1px solid rgba(0, 0, 0, 0.42)",
            borderRadius:"10px",
            '&, &::before': { 
                borderRadius:"10px",
            } 
        },
        '& .MuiFormControl-root': {
            '& label':{
                position:"sticky",
                color: "#006E54",
            },
            '& input': { 
               textAlign:"start",
                height: "90px"
            },

            '& input:focus': {
                 '&, &::after' :{
                     borderBottom:"1px solid #006E54 !important", 
                     borderRight:"1px solid #006E54 !important", 
                     borderTop:"1px solid #006E54 !important", 
                     borderLeft: "1px solid #006E54 !important",
                     borderRadius:"10px",
                     zIndex: "2"
                 },
            },
            '& legend': {
                display:"none"
            }
     }
    }
}))