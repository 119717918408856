import { Box, Grid, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../components/utils/inputs/FormikInputs.js'
import { useStyles } from './EditSucursalStyles.js'
import Map from '../../components/map/index.jsx'
import { useRecoilValue } from 'recoil'
import { afipData } from '../../recoilState/GlobalState.js'
import axios from 'axios'

export default function EditCajas({formik, coords, setCoords, provincias}) {
    const recoilAfipData = useRecoilValue(afipData);
    const styles = useStyles();
    const dataFormik = {
        merchantCajaNombre: {label: "Nombre de caja", placeholder:"Nombre"},
        merchantCajaNumero: {label: "Numero de caja", placeholder:"213"},
    }
  
    return (
    <>
    <Grid container width={"100%"} maxWidth={"800px"} padding={"20px 0 50px 0"}>
        <Grid item width={"100%"} display={"flex"} gap={4}>
            <Box width={"100%"} display={"flex"} gap={4} className={styles.containerInput}> 
                <FormikTextInput data={dataFormik} name={"merchantCajaNombre"} formik={formik} variant="standard"/> 
                <FormikTextInput data={dataFormik} name={"merchantCajaNumero"} formik={formik} variant="standard" /> 
            </Box>
        </Grid>
    </Grid>
    </>
  )
}
