import axiosPost from '../axiosPost';
import { Caja, MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantSucursalCajaIns = async (arg: UserCredentialsType, data: Caja) => {
    const body: AxiosPostBody = {
        "service": "MerchantSucursalCajaIns",
        "params": {
            merchantSucursalId: data.merchantSucursalId,
            merchantCajaNombre: data.merchantCajaNombre,
            merchantCajaNumero: data.merchantCajaNumero,
        }
    }

    try {
        const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        return result
        
    } catch (err) {
        console.log(err)
    }
}