import React, { useContext } from "react";

import styles from "./HeaderButton.module.scss";
import { useNavigate } from "react-router-dom";

export default function HeaderButtonTable({
    text,
    icon,
    data,
    placeholder,
    children,
    link,
    setModal,
    disabled = false,
}) {
    const navigate = useNavigate()
    // const { setModal, setDetailsModal, detailsModal } = useContext(Context);
    return (
        <div className={`${styles.tooltip} ${styles.bottom}`}>
                <span className={styles.tiptext}>{text}</span>
                <span className={styles.placeholder}>{placeholder}</span>
            <button
                disabled={disabled}
                className={`${styles.buttons} ${disabled ? styles.disabled : ""}`}
                onClick={() => {
                    setModal(data.action)
                }}
            >
                {icon
                    ? <img src={icon} alt={text} />
                    : children
                }
            </button>
        </div>
    );
}
