import { Box, Grid, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../components/utils/inputs/FormikInputs.js'
import { useStyles } from './AddSucursalStyles.js'
import Map from '../../components/map/index.jsx'
import { useRecoilValue } from 'recoil'
import { afipData } from '../../recoilState/GlobalState.js'
import axios from 'axios'

export default function AddCajas({formik, sucursales}) {
    const recoilAfipData = useRecoilValue(afipData);
    const [localidades, setLocalidades] = useState([]);
    const styles = useStyles();
    const dataFormik = {
        merchantSucursalId: {label: "Sucursal", placeholder:"Viamonte"},
        merchantCajaNombre: {label: "Nombre de caja", placeholder:"Nombre"},
        merchantCajaNumero: {label: "Numero de caja", placeholder:"7"},
    }
  
    return (
    <>
    <Grid container width={"100%"} maxWidth={"800px"} padding={"20px 0 50px 0"}>
        <Grid item width={"100%"} display={"flex"} gap={4}>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"merchantCajaNombre"} formik={formik} variant="standard"/> 
        </Box>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}>
            <FormikTextInput data={dataFormik} name={"merchantCajaNumero"} formik={formik} variant="standard" /> 
        </Box>
        </Grid>
        <Grid item width={"100%"} display={"flex"} gap={4}> 
            <Box display={"flex"} flexDirection={"column"} width={"100%"} className={styles.containerInput}> 
                <FormikSelectInput data={dataFormik} name={"merchantSucursalId"} formik={formik} options={sucursales} optionKey={"merchantSucursalId"} optionValue={"merchantSucursalId"} optionName={"merchantSucursalNombre"} />
            </Box>
        </Grid>
    </Grid>
    </>
  )
}
