import React from 'react';
import { FiltrosStyles } from '../Filtros.styles';
// assets
import reload from "../../assets/icons/actualizar.svg";
import download from "../../assets/icons/exportar.png";
// Form
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as XLSX from "xlsx";
// Components
import HeaderButton from "../../components/utils/button/HeaderButton";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import SubmitButton from "../../components/utils/button/SubmitButton";
import { FormikDateInput } from '../../components/utils/inputs/FormikInputs';

const validationSchema = yup.object({
    desde: yup.date().max(yup.ref('hasta'), 'No puede ser superior a la segunda fecha'),
    hasta: yup.date().max(new Date().toISOString().substring(0, 10), 'Fecha maxima hoy')
});

export default function Filtros(props) {
    const { filtros, setFiltros, cargando, updateList, rows, titles } = props;

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const handleExport = () => {
        const rowsFilter = rows.map(e => ({ 
        [titles[0]]: e[titles[0]],
        [titles[1]]: e[titles[1]],
        [titles[2]]: e[titles[2]],
        [titles[3]]: e[titles[3]],
        [titles[4]]: e[titles[4]],
        [titles[5]]: e[titles[5]],
        [titles[6]]: e[titles[6]],
        [titles[7]]: e[titles[7]],
        [titles[8]]: e[titles[8]],
        [titles[9]]: e[titles[9]],
        [titles[10]]: e[titles[10]],
        [titles[11]]: e[titles[11]],
        [titles[12]]: e[titles[12]],}))
        const worksheet = XLSX.utils.json_to_sheet(rowsFilter);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `Tabla-movimientos.xlsx`, { compression: true });
    }

    const classes = FiltrosStyles();
    const data = { desde: {label: "Desde"}, hasta: { label: 'Hasta' } };
    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className={classes.filtros}>
                <FormikDateInput
                    data={data}
                    name="desde"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <FormikDateInput
                    data={data}
                    name="hasta"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <div className={classes.marginNormal}>
                    <SubmitButton texto="Filtrar" />
                </div>
            </div>
            <Buttons>
                <HeaderButton
                    text={"Actualizar pagina"}
                    icon={reload}
                    updateList={updateList}
                />
            </Buttons>
            <Buttons>
                <HeaderButton
                    text={"Descargar tabla"}
                    icon={download}
                    handleClick={handleExport}
                />
            </Buttons>
        </form>
    )
};