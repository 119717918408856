import axiosPost from '../axiosPost';
import { UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

type Args = UserCredentialsType & {
    "merchantId": number
    "monedaCod": string
    "fecha": string
    "page": number
    "pageLen": number
}

export const MWMovimientoGet = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "MWMovimientoGet",
        "params": {
            "merchantId": arg.merchantId,
            "monedaCod": arg.monedaCod,
            "fecha": arg.fecha,
            "page": arg.page,
            "pageLen": arg.pageLen
        }
    }

    try {
        const result = await axiosPost('Y', body, arg);

        return {
            movimientos: result.result,
            totalDeMovimientos: result.status.id
        }
    } catch (err) {
        console.log(err)
    }
}