import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Grid, RadioGroup } from '@mui/material';
import { useFormik } from 'formik';
import {FormikRadioGroup} from "../../../utils/inputs/FormikInputs";
import { useStyles } from './styles';
import PlanCard from '../../planCard';
function Planes({formik, setIsOpen, planes}) {
    const dataFormik = {
      surCash: {label: "SurCash (Retiro de dinero)*"}
    }
    const styles = useStyles();

    const handleChange = (event) => {
      formik.setFieldValue('planCod', event.target.value);
    } 
    useEffect(() => {
        if(planes){
            formik.setFieldValue('planCod', planes[0]?.planCode);
        }
    }, [planes])
    
  return (
    <Grid width={"100%"} padding={"20px 0 20px 0"}>  
        <Box width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"} gap={"0px"}>
          <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name='planCode'
                value={formik.values.planCod}
                onChange={handleChange}
                className={styles.inputRazonSocial}>
            {planes?.map((plan, index) => (
                <PlanCard key={index} plan={plan} formik={formik} />
            ))}
            </RadioGroup>
        </Box>
        <Box className={styles.containerInput} paddingTop={"12px"} marginTop={"12px"} borderTop={"1px solid #E5E5E5"} >
          <FormikRadioGroup name={"surCash"} formik={formik} label={dataFormik.surCash.label}/>
        </Box>
        <Box width={"100%"} marginTop={"4px"}>
          <p className={styles.text}>*Los plazos de acreditación están expresados en días hábiles a contar desde
            las 00 horas del dia inmediato siguiente a la fecha de las operaciones a liquidar.</p>
          <p className={styles.text}>*Según tu condición fiscal, es posible que AFIP y los fiscos provinciales apliquen retenciones y/o percepciones impositivas en tus cobros.</p>
          <p className={styles.text}>*La acreditación por El servicio de SUR cash es inmediato y sujeto a los términos y condiciones del plan.</p>
        </Box>
        <Box className={styles.containerInput} display={"flex"} alignItems={"center"}>
        <Checkbox
                checked={formik.values.tyc}
                onChange={() => formik.setFieldValue('tyc', !formik.values.tyc)}
                color="success" 
            />
            <p style={{color:"#595959", fontSize:"14px"}}>Acepto <a style={{color: "#006E54"}} href='https://info.miaid.me/tycsurmerchant.md' target="_blank">términos y condiciones</a> y <a style={{color: "#006E54"}} href='https://info.miaid.me/politicaprivacidadsurmerchant.md' target="_blank">políticas de privacidad</a></p>
        </Box>
    </Grid>
  )
}

export {Planes}
