import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(()=> (
    {
        root: { 
            '& .MuiButtonBase-root': {
                borderRadius: '50px',
                textTransform: 'none',
                fontSize: '18px',
                fontWeight: "bold",
                color: '#fff',
                backgroundColor:"#006E54",
                '&:hover': {
                    backgroundColor: "#006E54"
                }
            },
            '& .MuiButtonBase-root:disabled':{
                background: '#D6D6D6',
                color: '#fff'
            }
        },
        btn: { 
            backgroundColor: '#006E54',
            width: '374px',
            display: 'flex',
            justifyContent: 'center',
            
            padding: '12px',
            fontSize: '22px',
            fontWeight: 700,
            lineHeight: '26px',
            
        },
        medium: {
            width: '250px'
        },
        big:{
            width: '100%'
        },
        transparent: {
            background: '#00000000 !important',
            color: 'rgba(0, 0, 0, 0.87) !important',
        }
    }
))