import React, { useState } from 'react';
import { DropZone } from '../../../components/alta-comercios/dropzone';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { afipData, comercioData, userCredentials } from '../../../recoilState/GlobalState';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UrlDocumentosGet } from '../../../services/hooli-services/Documentos/UrlDocumentosGet';
import { DocumentosPut } from '../../../services/hooli-services/Documentos/DocumentosPut';

export function ConstanciaSO({nextStep,setStepDocumentos}) {
    const [file,setFile] = useState(null)
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [comercio,setDataComercios] = useRecoilState(comercioData);
    const recoilAfipData = useRecoilValue(afipData);
    const urlDoc = async() => {
      // console.log(recoilAfipData.cuit.replaceAll('-', ''))
      const url = await UrlDocumentosGet(credentials, `${recoilAfipData.cuit.replaceAll('-', '')}_SO.pdf`, "SO" ,recoilAfipData.cuit);
      await sendFile(url.result[0].url)
    }
    
    const sendFile = async (url) => {
      // console.log(file[0]) 
      await DocumentosPut(url, file[0])
    }

    const handleSubmit = ()=> {
      urlDoc();
      setDataComercios({...comercio, documentosSOCantidad: 1});
      setStepDocumentos({
        step:4,
        title: "Otros documentos",
        subtitle:"Subí otros documentos como tu Constancia de exención a Ingresos Brutos"
      })
    }
  return (
   <> 
    <DropZone file={file} setFile={setFile}/>
    <Box> 
            <ButtonGradient size={"medium"} text={"Continuar"} action={handleSubmit} disabled={!file}/>
          </Box>
          <Box marginTop={"15px"} marginBottom={"15px"}> 
          <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>setStepDocumentos({
           step:2, 
           title: "Constancia de Inscripción de Ingresos Brutos",
           subtitle: "Podés descargar tu constancia de inscripción desde el sitio web de la AFIP"
      })}/>
        </Box>
   </>
        
    
  )
}

 