import axiosPost from '../axiosPost';
import { MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantSucursalCajaDel = async (arg: UserCredentialsType, merchantCajaId: number) => {
    const body: AxiosPostBody = {
        "service": "MerchantSucursalCajaDel",
        "params": {
            merchantCajaId: merchantCajaId
        }
    }

    try {
        const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        return result
        
    } catch (err) {
        console.log(err)
    }
}