import { Box, Grid, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../components/utils/inputs/FormikInputs.js'
import { useStyles } from './EditSucursalStyles.js'
import Map from '../../components/map/index.jsx'
import { useRecoilValue } from 'recoil'
import { afipData } from '../../recoilState/GlobalState.js'
import axios from 'axios'

export default function EditEmpleados({formik, sucursales, roles}) {
    const recoilAfipData = useRecoilValue(afipData);
    const date = new Date().toISOString().slice(0, 10);
    const styles = useStyles();
    const dataFormik = {
        merchantUsuarioRolId: {label: "Rol", placeholder:"Viamonte"},
        merchantUsuarioSucursalId: {label: "Sucursal", placeholder:"Nombre"},
    }
    useEffect(() => {
        formik.setFieldValue("merchantUsuarioFecha", date);
    }, [sucursales])
  
    return (
    <>
    <Grid container width={"100%"} maxWidth={"800px"} padding={"20px 0 50px 0"}>
        {/* <Grid item width={"100%"} display={"flex"} gap={4}>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"merchantCajaNombre"} formik={formik} variant="standard"/> 
        </Box>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}>
            <FormikTextInput data={dataFormik} name={"merchantCajaNumero"} formik={formik} variant="standard" /> 
        </Box>
        </Grid> */}
        <Grid item width={"100%"} display={"flex"} gap={4}> 
            <Box display={"flex"} flexDirection={"column"} width={"50%"} className={styles.containerInput}> 
                <FormikSelectInput data={dataFormik} name={"merchantUsuarioSucursalId"} formik={formik} options={sucursales} optionKey={"merchantSucursalId"} optionValue={"merchantSucursalId"} optionName={"merchantSucursalNombre"} />
            </Box>
            <Box display={"flex"} flexDirection={"column"} width={"50%"} className={styles.containerInput}> 
                <FormikSelectInput data={dataFormik} name={"merchantUsuarioRolId"} formik={formik} options={roles} optionKey={"rolId"} optionValue={"rolId"} optionName={"rolNombre"} />
            </Box>
        </Grid>
    </Grid>
    </>
  )
}
