import axiosPost from '../axiosPost';
import { EmpleadoDel, MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantEmpleadoDel = async (arg: UserCredentialsType, data: EmpleadoDel) => {
    const body: AxiosPostBody = {
        "service": "MerchantEmpleadoDel",
        "params": {
            merchantUsuarioUsuarioId: data.merchantUsuarioUsuarioId,
            merchantId: data.merchantId,
            merchantUsuarioFecha: data.merchantUsuarioFecha,
        }
    }

    try {
        const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        return result
        
    } catch (err) {
        console.log(err)
    }
}